var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// @ts-nocheck
import CloseIcon from "@mui/icons-material/Close";
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TimePicker from "@mui/lab/TimePicker";
import { Autocomplete, Box, Button, Card, CardContent, Checkbox, CircularProgress, DialogTitle, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, ListItemText, MenuItem, Select, Switch, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography, } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Form as RAForm, DateInput as RADateInput, minValue as RAminValue, useRefresh, } from "react-admin";
import { Field } from "formik";
import _ from "lodash";
import React, { useState } from "react";
import Breadcrumb from "../layout/helper/Breadcrumb";
import makeStyles from "@mui/styles/makeStyles";
import { alpha, styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import Menu from "@mui/material/Menu";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
var general = {};
var dialog = {};
var dashboard = {};
var filter = {};
var editComp = {}; //similar to filter, but without setting session storage
general.breadcrumb = function (_a) {
    var title = _a.title, trail = _a.trail;
    return (_jsx(Grid, { item: true, xs: 12, children: _jsxs(Breadcrumb, { title: title, children: [_jsx(Typography, { to: "/", variant: "subtitle2", color: "inherit", className: "link-breadcrumb", children: trail.shift() }), trail.map(function (el, index) { return (_jsx(Typography, { variant: "subtitle2", color: index === (trail === null || trail === void 0 ? void 0 : trail.length) - 1 ? "primary" : "inherit", className: "link-breadcrumb", children: el }, el)); })] }) }));
};
dialog.header = function (_a) {
    var title = _a.title, handleClose = _a.handleClose;
    return (_jsxs(DialogTitle, { className: "normal-text large-bold", style: {
            whiteSpace: "nowrap",
        }, children: [title !== null && title !== void 0 ? title : "", _jsx(IconButton, { style: {
                    float: "right",
                    padding: 0,
                    paddingTop: 5,
                }, color: "primary", "aria-label": "add", size: "large", onClick: handleClose, children: _jsx(CloseIcon, { fontSize: "large" }) })] }));
};
dialog.submitButton = function (_a) {
    var isSubmitting = _a.isSubmitting, disabled = _a.disabled, text = _a.text, onClick = _a.onClick, variant = _a.variant;
    return (_jsx(Button, { variant: variant !== null && variant !== void 0 ? variant : "contained", type: onClick ? undefined : "submit", onClick: onClick, color: "primary", disabled: isSubmitting || disabled, className: "button-all", children: isSubmitting ? _jsx(CircularProgress, { size: 20 }) : text !== null && text !== void 0 ? text : "Submit" }));
};
dialog.footerButton = function (_a) {
    var onClick = _a.onClick, icon = _a.icon, text = _a.text, variant = _a.variant, color = _a.color, isLoading = _a.isLoading;
    return (_jsx(Button, { variant: variant !== null && variant !== void 0 ? variant : "text", color: color !== null && color !== void 0 ? color : "secondary", onClick: onClick, disabled: isLoading, children: isLoading ? (_jsx(CircularProgress, { size: 24 })) : (_jsxs(React.Fragment, { children: [icon, " ", text] })) }));
};
dialog.gridButton = function (_a) {
    var onClick = _a.onClick, icon = _a.icon, text = _a.text, xs = _a.xs, sm = _a.sm, md = _a.md, lg = _a.lg, xl = _a.xl, variant = _a.variant, color = _a.color;
    return (_jsx(Grid, { item: true, xs: xs, sm: sm, md: md, lg: lg, xl: xl, children: _jsxs(Button, { variant: variant !== null && variant !== void 0 ? variant : "text", color: color !== null && color !== void 0 ? color : "secondary", onClick: onClick, sx: { height: "94.68%", margin: 0, padding: 0 }, children: [icon, " ", text] }) }));
};
dialog.subtitle = function (_a) {
    var name = _a.name, button = _a.button, xs = _a.xs, sm = _a.sm, md = _a.md, lg = _a.lg, xl = _a.xl;
    return (_jsx(Grid, { item: true, xs: xs !== null && xs !== void 0 ? xs : 12, sm: sm, md: md, lg: lg, xl: xl, children: _jsxs("div", { style: {
                display: "flex",
                alignItems: "center",
            }, children: [_jsx(Typography, { style: { fontWeight: "500", fontSize: 18 }, children: name }), button && (_jsx(Button, { size: "small", variant: "text", color: "secondary", onClick: button.onClick, children: button.icon }))] }) }, name));
};
dialog.textField = function (props) { return _jsx(Field, __assign({}, props, { as: d_tf })); };
var d_tf = function (_a) {
    var _b;
    var label = _a.label, xs = _a.xs, sm = _a.sm, md = _a.md, lg = _a.lg, xl = _a.xl, form = _a.form, required = _a.required, row = _a.row, disabled = _a.disabled, unit = _a.unit, unit_position = _a.unit_position, tooltip = _a.tooltip, readOnly = _a.readOnly, props = __rest(_a, ["label", "xs", "sm", "md", "lg", "xl", "form", "required", "row", "disabled", "unit", "unit_position", "tooltip", "readOnly"]);
    var errors = form.errors, touched = form.touched;
    var err = _.get(touched, props.name) && _.get(errors, props.name);
    // if (!label && required) unit += " *";
    var _c = useState(false), showCopyTooltip = _c[0], setShowCopyTooltip = _c[1];
    var handleCopy = function () {
        navigator.clipboard.writeText(props.value || "");
        setShowCopyTooltip(true);
        setTimeout(function () { return setShowCopyTooltip(false); }, 1500);
    };
    return (_jsx(Grid, { item: true, xs: xs, sm: sm, md: md, lg: lg, xl: xl, children: _jsx(Tooltip, { title: tooltip ? (typeof tooltip === "string" ? tooltip : props.value) : "", children: _jsx("div", { style: {
                    position: "relative",
                    display: "inline-block",
                    width: "100%",
                }, children: _jsx(CssEditTextField, __assign({}, props, { label: label, error: err !== undefined, helperText: err, disabled: disabled !== null && disabled !== void 0 ? disabled : false, required: required, value: (_b = props.value) !== null && _b !== void 0 ? _b : "", InputProps: {
                        endAdornment: (_jsxs(InputAdornment, { position: "end", children: [unit && (_jsx(Typography, { sx: {
                                        color: err !== undefined ? "error.main" : "text.primary",
                                    }, children: unit })), _jsx(Tooltip, { title: showCopyTooltip ? "Copied!" : "Copy", placement: "top", children: _jsx(IconButton, { onClick: handleCopy, size: "small", style: { padding: "2px" }, children: _jsx(ContentCopyIcon, { style: { fontSize: "0.8rem" } }) }) })] })),
                        readOnly: readOnly,
                    }, InputLabelProps: { shrink: !!unit || undefined }, 
                    // fullWidth
                    margin: "normal", variant: readOnly ? "filled" : "outlined", multiline: !!row, rows: row })) }) }) }, label));
};
dialog.numberField = function (props) { return _jsx(Field, __assign({}, props, { as: d_nf })); };
var d_nf = function (_a) {
    var _b;
    var label = _a.label, xs = _a.xs, sm = _a.sm, md = _a.md, lg = _a.lg, xl = _a.xl, form = _a.form, required = _a.required, allowNegative = _a.allowNegative, unit = _a.unit, unit_position = _a.unit_position, readOnly = _a.readOnly, props = __rest(_a, ["label", "xs", "sm", "md", "lg", "xl", "form", "required", "allowNegative", "unit", "unit_position", "readOnly"]);
    var errors = form.errors, touched = form.touched, handleBlur = form.handleBlur, setFieldValue = form.setFieldValue;
    var err = _.get(touched, props.name) && _.get(errors, props.name);
    if (!label && required)
        unit += " *";
    return (_jsx(Grid, { item: true, xs: xs, sm: sm, md: md, lg: lg, xl: xl, children: _jsx(TextField, __assign({}, props, { label: label, error: err !== undefined, helperText: err, required: required, value: (_b = props.value) !== null && _b !== void 0 ? _b : "", onBlur: function (e) {
                return !isNaN(e.target.value) ? handleBlur(e) : setFieldValue(props.name, "");
            }, onChange: function (e) {
                return (!isNaN(e.target.value) ||
                    !e.target.value ||
                    (allowNegative && e.target.value === "-")) &&
                    setFieldValue(props.name, e.target.value.replace(allowNegative ? / /g : / |-/g, "")) &&
                    useRefresh();
            }, InputProps: {
                endAdornment: unit && (_jsx(InputAdornment, { position: unit_position !== null && unit_position !== void 0 ? unit_position : "end", children: _jsx(Typography, { sx: {
                            color: err !== undefined ? "error.main" : "text.primary",
                        }, children: unit }) })),
                readOnly: readOnly,
            }, InputLabelProps: { shrink: !!unit || undefined }, fullWidth: true, margin: "normal", variant: readOnly ? "filled" : "outlined", style: { margin: 0 } })) }, label));
};
dialog.timePicker = function (props) { return _jsx(Field, __assign({}, props, { as: d_tp })); };
var d_tp = function (_a) {
    var label = _a.label, xs = _a.xs, sm = _a.sm, md = _a.md, lg = _a.lg, xl = _a.xl, form = _a.form, required = _a.required, maxTime = _a.maxTime, minTime = _a.minTime, hideClock = _a.hideClock, props = __rest(_a, ["label", "xs", "sm", "md", "lg", "xl", "form", "required", "maxTime", "minTime", "hideClock"]);
    var errors = form.errors, touched = form.touched, setFieldValue = form.setFieldValue;
    var err = _.get(touched, props.name) && _.get(errors, props.name);
    return (_jsx(Grid, { item: true, xs: xs, sm: sm, md: md, lg: lg, xl: xl, children: _jsx(TimePicker, __assign({}, props, { label: label, onChange: function (newValue) {
                setFieldValue(props.name, newValue);
                useRefresh();
            }, fullWidth: true, margin: "normal", variant: "outlined", style: { margin: 0 }, inputFormat: "HH:mm", mask: "__:__", ampm: false, minTime: minTime, maxTime: maxTime, value: props.value || null, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { error: err !== undefined || params.error, helperText: err, required: required, InputProps: hideClock ? undefined : params.InputProps }))); } })) }, label));
};
dialog.timePicker_daily_work_stats = function (props) { return (_jsx(Field, __assign({}, props, { as: d_tp_daily_work_stats }))); };
var d_tp_daily_work_stats = function (_a) {
    var label = _a.label, xs = _a.xs, sm = _a.sm, md = _a.md, lg = _a.lg, xl = _a.xl, form = _a.form, required = _a.required, maxTime = _a.maxTime, minTime = _a.minTime, hideClock = _a.hideClock, props = __rest(_a, ["label", "xs", "sm", "md", "lg", "xl", "form", "required", "maxTime", "minTime", "hideClock"]);
    var errors = form.errors, touched = form.touched, setFieldValue = form.setFieldValue;
    var err = _.get(touched, props.name) && _.get(errors, props.name);
    return (_jsx(Grid, { item: true, xs: xs, sm: sm, md: md, lg: lg, xl: xl, children: _jsx(TimePicker, __assign({}, props, { label: label, onChange: function (newValue) {
                setFieldValue(props.name, newValue.toTimeString().split(" ")[0]);
                useRefresh();
            }, fullWidth: true, margin: "normal", variant: "outlined", style: { margin: 0 }, inputFormat: "HH:mm:ss", mask: "__:__:__", ampm: true, minTime: minTime, maxTime: maxTime, value: props.value || null, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { error: err !== undefined || params.error, helperText: err, required: required, InputProps: hideClock ? undefined : params.InputProps }))); } })) }, label));
};
dialog.datePicker = function (props) { return _jsx(Field, __assign({}, props, { as: d_dp })); };
var d_dp = function (_a) {
    var label = _a.label, xs = _a.xs, sm = _a.sm, md = _a.md, lg = _a.lg, xl = _a.xl, form = _a.form, required = _a.required, maxTime = _a.maxTime, minTime = _a.minTime, hideClock = _a.hideClock, props = __rest(_a, ["label", "xs", "sm", "md", "lg", "xl", "form", "required", "maxTime", "minTime", "hideClock"]);
    var errors = form.errors, touched = form.touched, setFieldValue = form.setFieldValue;
    var err = _.get(touched, props.name) && _.get(errors, props.name);
    return (_jsx(Grid, { item: true, xs: xs, sm: sm, md: md, lg: lg, xl: xl, children: _jsx(RAForm, { children: _jsx(RADateInput, { source: label, 
                // minDate={minDate ?? null}
                //[back] min and max date not working
                validate: RAminValue(new Date("2024-06-06")), 
                // maxDate={maxDate  ?? null}
                value: "06/07/2000", 
                // value={props.value || null}
                // onChange={(newValue) => setFieldValue(props.name, newValue)}
                // defaultValue={filters?.[name] ? new Date(filters[name]) : getTodaysDateChinaTimeZone()}
                defaultValue: new Date("6/14/2024") }) }) }, label));
};
dialog.autocomplete = function (props) { return _jsx(Field, __assign({}, props, { as: d_ac })); };
var d_ac = function (_a) {
    var label = _a.label, xs = _a.xs, sm = _a.sm, md = _a.md, lg = _a.lg, xl = _a.xl, form = _a.form, required = _a.required, options = _a.options, open = _a.open, setOpen = _a.setOpen, loading = _a.loading, props = __rest(_a, ["label", "xs", "sm", "md", "lg", "xl", "form", "required", "options", "open", "setOpen", "loading"]);
    var errors = form.errors, touched = form.touched, setFieldTouched = form.setFieldTouched, setFieldValue = form.setFieldValue;
    var err = _.get(touched, props.name) && _.get(errors, props.name);
    // options format: ['hi1', 'hi2'...]
    return (_jsx(Grid, { item: true, xs: xs, sm: sm, md: md, lg: lg, xl: xl, children: _jsx(Autocomplete, __assign({}, props, { openOnFocus: true, autoComplete: true, fullWidth: true, options: options, onChange: function (e, value) {
                setFieldValue(props.name, value);
                useRefresh();
            }, onBlur: function (e) {
                var v = options.find(function (el) { return el.toLowerCase() === e.target.value.toLowerCase(); });
                if (v)
                    setFieldValue(props.name, v);
                setFieldTouched(props.name);
            }, onOpen: setOpen
                ? function () {
                    setOpen(true);
                }
                : undefined, onClose: setOpen
                ? function () {
                    setOpen(false);
                }
                : undefined, loading: loading, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { label: label, margin: "normal", variant: "outlined", error: err !== undefined, helperText: err, required: required, style: { margin: 0 }, InputProps: __assign(__assign({}, params.InputProps), { endAdornment: (_jsxs(React.Fragment, { children: [loading ? _jsx(CircularProgress, { size: 20 }) : null, params.InputProps.endAdornment] })) }) }))); } })) }, label));
};
dialog.dropDownList = function (props) { return _jsx(Field, __assign({}, props, { as: d_ddl })); };
var d_ddl = function (_a) {
    var label = _a.label, xs = _a.xs, sm = _a.sm, md = _a.md, lg = _a.lg, xl = _a.xl, form = _a.form, required = _a.required, options = _a.options, value = _a.value, setFields = _a.setFields, renderValue = _a.renderValue, callback = _a.callback, setFilters = _a.setFilters, props = __rest(_a, ["label", "xs", "sm", "md", "lg", "xl", "form", "required", "options", "value", "setFields", "renderValue", "callback", "setFilters"]);
    var errors = form.errors, touched = form.touched, handleChange = form.handleChange, setFieldValue = form.setFieldValue;
    var err = _.get(touched, props.name) && _.get(errors, props.name);
    if (!props.defaultValue)
        props.defaultValue = "";
    return (_jsx(Grid, { item: true, xs: xs, sm: sm, md: md, lg: lg, xl: xl, children: _jsxs(FormControl, __assign({}, props, { style: {
                width: "100%",
            }, required: required, error: err !== undefined, variant: "outlined", children: [_jsx(InputLabel, { id: "autowidth-label", children: label }), _jsx(Select, __assign({}, props, { sx: {
                        borderRadius: "10px",
                        width: "100%",
                        boxShadow: "0 0 2px 2px #dddddd",
                    }, labelId: label, value: value !== null && value !== void 0 ? value : "", label: label || undefined, id: label, fullWidth: true, displayEmpty: true, disabled: props.disabled || false, renderValue: renderValue, onChange: function (e) {
                        if (setFields) {
                            setFields.forEach(function (el) {
                                if ((!el["if"] || e.target.value === el["if"]) && el["name"])
                                    setFieldValue(el["name"], el["value"]);
                                else if ((!el["if"] || e.target.value === el["if"]) &&
                                    el["label"])
                                    setFieldValue(el["label"], el["value"]);
                            });
                        }
                        if (setFilters) {
                            setFilters(e.target.value);
                        }
                        handleChange(e);
                        if (typeof callback === "function")
                            callback(e.target.value);
                        useRefresh();
                    }, children: options &&
                        (options === null || options === void 0 ? void 0 : options.map(function (el) {
                            return el.name ? (_jsx(MenuItem, { value: el.value, children: el.name }, el.value)) : (_jsx(MenuItem, { value: el.value, children: el.label }, el.value));
                        } //assign labels as name for some list
                        )) })), _jsx(FormHelperText, { children: err })] })) }, label));
};
dialog.multiSelect = function (props) { return _jsx(Field, __assign({}, props, { as: d_ms })); };
var d_ms = function (_a) {
    var label = _a.label, xs = _a.xs, sm = _a.sm, md = _a.md, lg = _a.lg, xl = _a.xl, form = _a.form, required = _a.required, options = _a.options, value = _a.value, setFields = _a.setFields, setValue = _a.setValue, renderValue = _a.renderValue, callback = _a.callback, props = __rest(_a, ["label", "xs", "sm", "md", "lg", "xl", "form", "required", "options", "value", "setFields", "setValue", "renderValue", "callback"]);
    var errors = form.errors, touched = form.touched, handleChange = form.handleChange, setFieldValue = form.setFieldValue;
    var err = _.get(touched, props.name) && _.get(errors, props.name);
    if (!Array.isArray(value))
        value = [];
    return (_jsx(Grid, { item: true, xs: xs, sm: sm, md: md, lg: lg, xl: xl, children: _jsxs(FormControl, __assign({}, props, { variant: "outlined", style: { width: "100%" }, required: required, error: err !== undefined, children: [_jsx(InputLabel, { id: "autowidth-label", children: label }), _jsx(Select, __assign({}, props, { sx: {
                        borderRadius: "10px",
                        width: "100%",
                        boxShadow: "0 0 2px 2px #dddddd",
                        justifyContent: "center",
                    }, labelId: label, value: value, label: label || undefined, id: label, multiple: true, fullWidth: true, displayEmpty: true, disabled: props.disabled || false, renderValue: function (selected) {
                        return options
                            .filter(function (el) { return selected.includes(el.value); })
                            .map(function (el) { return el.name; })
                            .join(", ");
                    }, onChange: function (e) {
                        if (setFields) {
                            setFields.forEach(function (el) {
                                if (!el["if"] || e.target.value === el["if"])
                                    setFieldValue(el["name"], el["value"]);
                            });
                        }
                        handleChange(e);
                        if (typeof callback === "function")
                            callback(e.target.value);
                        if (setValue) {
                            setValue(e.target.value);
                        }
                        useRefresh();
                    }, children: options &&
                        (options === null || options === void 0 ? void 0 : options.map(function (el) { return (_jsxs(MenuItem, { value: el.value, children: [_jsx(Checkbox, { checked: value.includes(el.value) }), _jsx(ListItemText, { primary: el.name })] }, el.value)); })) })), _jsx(FormHelperText, { children: err })] })) }, label));
};
dialog.switch = function (props) { return _jsx(Field, __assign({}, props, { as: d_s })); };
var d_s = function (_a) {
    var _b;
    var label = _a.label, xs = _a.xs, sm = _a.sm, md = _a.md, lg = _a.lg, xl = _a.xl, form = _a.form, required = _a.required, displayTrue = _a.displayTrue, displayFalse = _a.displayFalse, color1 = _a.color1, color2 = _a.color2, props = __rest(_a, ["label", "xs", "sm", "md", "lg", "xl", "form", "required", "displayTrue", "displayFalse", "color1", "color2"]);
    return (_jsxs(Grid, { item: true, xs: xs, sm: sm, md: md, lg: lg, xl: xl, children: [_jsx(InputLabel, { children: label }), _jsx(FormControlLabel, { label: props.value ? displayTrue : displayFalse, sx: {
                    color: props.value ? color1 !== null && color1 !== void 0 ? color1 : "green" : color2 !== null && color2 !== void 0 ? color2 : "grey",
                    height: 34,
                }, control: _jsx(Switch, __assign({}, props, { value: !!props.value, checked: (_b = props.value) !== null && _b !== void 0 ? _b : undefined })) })] }, label));
};
dialog.toggleButton = function (props) { return _jsx(Field, __assign({}, props, { as: d_tb })); };
var d_tb = function (_a) {
    var label = _a.label, xs = _a.xs, sm = _a.sm, md = _a.md, lg = _a.lg, xl = _a.xl, form = _a.form, required = _a.required, options = _a.options, buttonStyle = _a.buttonStyle, props = __rest(_a, ["label", "xs", "sm", "md", "lg", "xl", "form", "required", "options", "buttonStyle"]);
    var setFieldValue = form.setFieldValue;
    return (_jsx(Grid, { item: true, xs: xs, sm: sm, md: md, lg: lg, xl: xl, children: _jsx(ToggleButtonGroup, __assign({}, props, { color: "info", exclusive: true, onChange: function (e) {
                setFieldValue(props.name, e.target.value);
                useRefresh();
            }, children: options === null || options === void 0 ? void 0 : options.map(function (el) { return (_jsx(ToggleButton, { value: el.value, style: buttonStyle, children: el.name }, el.value)); }) })) }, label));
};
dashboard.card = function (_a) {
    var contentStyle = _a.contentStyle, gridHeight = _a.gridHeight, cardHeight = _a.cardHeight, xs = _a.xs, sm = _a.sm, md = _a.md, lg = _a.lg, xl = _a.xl, color = _a.color, footerText = _a.footerText, footerIcon = _a.footerIcon, customRender = _a.customRender, data = _a.data, rowLeftPadding = _a.rowLeftPadding, rowBullet = _a.rowBullet, rowContentSize = _a.rowContentSize;
    rowContentSize = rowContentSize !== null && rowContentSize !== void 0 ? rowContentSize : 8;
    return (_jsx(Grid, { item: true, xs: xs, sm: sm, md: md, lg: lg, xl: xl, height: gridHeight, children: _jsxs(Card, { children: [_jsx(CardContent, { style: contentStyle !== null && contentStyle !== void 0 ? contentStyle : { padding: "16px" }, children: customRender ? (customRender) : (_jsx(Grid, { container: true, justifyContent: "space-between", alignItems: "center", children: data.map(function (el, i) { return (_jsxs(React.Fragment, { children: [el.title && (_jsx(Grid, { item: true, xs: 12, paddingBottom: 0.5, paddingTop: i === 0 ? 0 : 1, children: _jsx(Typography, { variant: "subtitle1", children: el.title }) })), el.row &&
                                    el.row.map(function (el, i) { return (_jsxs(React.Fragment, { children: [rowBullet && (_jsx(Grid, { item: true, xs: 0.3, children: _jsx(Typography, { variant: "h6", paddingLeft: rowLeftPadding, children: i + 1 + ". " }) })), _jsx(Grid, { item: true, xs: rowContentSize - (rowBullet ? 0.3 : 0), children: _jsx(Tooltip, { title: el[0], followCursor: true, children: _jsx(Typography, { noWrap: true, variant: "h6", paddingLeft: rowBullet ? 0 : rowLeftPadding, children: el[0] }) }) }), _jsx(Grid, { item: true, xs: 12 - rowContentSize, align: "right", children: _jsx(Typography, { variant: "h6", children: el[1] }) })] })); })] })); }) })) }), (footerText || footerIcon) && (_jsx(Box, { sx: { backgroundColor: color }, children: _jsxs(Grid, { container: true, justifyContent: "space-between", style: {
                            textAlign: "center",
                            padding: "8px",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            color: "white",
                        }, children: [_jsx(Grid, { item: true, children: _jsxs(Typography, { variant: "body2", children: [" ", footerText, " "] }) }), _jsx(Grid, { item: true, children: _jsxs(Typography, { variant: "body2", children: [" ", footerIcon ? React.createElement(footerIcon, {}) : "", " "] }) })] }) }))] }) }));
};
//similar to filter, but without setting session storage - for filter
editComp.filterTextField = function (_a) {
    var _b;
    var filters = _a.filters, setFilters = _a.setFilters, name = _a.name, label = _a.label, width = _a.width, disabled = _a.disabled, fullWidth = _a.fullWidth, xs = _a.xs, sm = _a.sm, md = _a.md, lg = _a.lg, xl = _a.xl, givenValue = _a.givenValue;
    return (_jsx(Grid, { item: true, xs: xs, sm: sm, md: md, lg: lg, xl: xl, children: _jsx(TextField, { fullWidth: fullWidth, label: label, id: name, name: name, variant: "outlined", value: givenValue ? givenValue : (_b = filters[name]) !== null && _b !== void 0 ? _b : "", size: "small", onChange: function (e) {
                var newFilter = __assign({}, filters);
                newFilter[e.target.name] = e.target.value;
                setFilters(newFilter);
                useRefresh();
            }, disabled: disabled !== null && disabled !== void 0 ? disabled : false, sx: {
                width: "100%",
                opacity: 0.6,
                "& .MuiOutlinedInput-root": {
                    height: "2.93em",
                    borderTopLeftRadius: "20px",
                    borderTopRightRadius: "20px",
                    // boxShadow: '0px 6px 8px gray',
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                    },
                    "& input": {
                        paddingTop: "1.2em", // Move the text down a bit when editing
                        fontWeight: 900,
                        fontSize: "1.1em",
                    },
                },
                "& .MuiInputLabel-root": {
                    fontSize: "0.8em",
                    paddingTop: "0.23em",
                    fontWeight: "900",
                },
            } }) }));
};
//similar to filter, but without setting session storage - for filter
editComp.dropDownList = function (_a) {
    var _b, _c;
    var filters = _a.filters, setFilters = _a.setFilters, name = _a.name, label = _a.label, minWidth = _a.minWidth, options = _a.options, formControlSize = _a.formControlSize, notIncludeAllSelection = _a.notIncludeAllSelection, gridBGColor = _a.gridBGColor, xs = _a.xs, sm = _a.sm, md = _a.md, lg = _a.lg, xl = _a.xl;
    var gridStyle = {};
    if (gridBGColor)
        gridStyle['backgroundColor'] = gridBGColor;
    return (_jsx(Grid, { item: true, style: gridStyle, xs: xs, sm: sm, md: md, lg: lg, xl: xl, children: _jsxs(FormControl, { sx: { width: '100%' }, xs: xs, sm: sm, md: md, lg: lg, xl: xl, size: formControlSize === 'medium' ? 'medium' : "small", children: [_jsx(StyledInputLabel, { id: name, children: label }), _jsxs(StyledSelect, { labelId: name, id: name, value: (_b = filters[name]) !== null && _b !== void 0 ? _b : "", label: label, name: name, variant: "outlined", 
                    /* input={<CssFdropDownList />} */
                    onChange: function (e) {
                        var newFilter = __assign({}, filters);
                        newFilter[e.target.name] = e.target.value;
                        setFilters(newFilter);
                        useRefresh();
                    }, sx: {
                        width: '100%',
                        '& .MuiSelect-select': {
                            fontWeight: 900,
                        },
                        '& .MuiOutlinedInput-root': {
                            height: '2.93em',
                            borderTopLeftRadius: '20px',
                            borderTopRightRadius: '20px',
                            boxShadow: '0px 6px 8px gray',
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'black',
                            },
                            '& input': {
                                paddingTop: '1.2em', // Move the text down a bit when editing
                                fontWeight: 900,
                                fontSize: "1.1em",
                            },
                        },
                        '& .MuiInputLabel-root': {
                            fontSize: '0.8em',
                            paddingTop: '0.23em',
                            fontWeight: "900",
                        },
                    }, children: [!notIncludeAllSelection && _jsx(MenuItem, { value: "", children: _jsx("em", { children: "---------" }) }), (_c = options === null || options === void 0 ? void 0 : options[name]) === null || _c === void 0 ? void 0 : _c.map(function (el) { return typeof el === 'string' ?
                            _jsx(MenuItem, { value: el, children: el }, "empty") :
                            _jsx(MenuItem, { value: el.value, children: el.label || el.name }, el.value); })] })] }) }));
};
var CssEditTextField = styled(TextField)(function (_a) {
    var theme = _a.theme;
    return ({
        "& .MuiOutlinedInput-root": {
            // borderRadius: "20px",
            width: "100%",
            "& fieldset": {
                borderRadius: "10px",
                boxShadow: "0 0 2px 2px #dddddd",
            },
            "&:hover fieldset": {
                borderColor: "black",
            },
        },
        "& .MuiInputBase-input": {
            overflow: "auto",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
    });
});
var CssEditTextField2 = styled(TextField)(function (_a) {
    var theme = _a.theme;
    return ({
        "& .MuiOutlinedInput-root": {
            width: "100%",
            "& fieldset": {
                // width: "10rem",
                borderRadius: "10px",
                boxShadow: "0 0 2px 2px #dddddd",
            },
            "&:hover fieldset": {
                borderColor: "black",
            },
        },
        "& .MuiInputBase-input": {
            overflow: "auto",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
    });
});
//similar to filter, but without setting session storage - for edit dialog
editComp.textField = function (_a) {
    var _b;
    var filters = _a.filters, setFilters = _a.setFilters, name = _a.name, label = _a.label, width = _a.width, disabled = _a.disabled, fullWidth = _a.fullWidth, xs = _a.xs, sm = _a.sm, md = _a.md, lg = _a.lg, xl = _a.xl, givenValue = _a.givenValue;
    return (_jsx(Grid, { item: true, xs: xs, sm: sm, md: md, lg: lg, xl: xl, children: _jsx(CssEditTextField2, { fullWidth: fullWidth, label: label, id: name, name: name, variant: "outlined", value: givenValue ? givenValue : (_b = filters[name]) !== null && _b !== void 0 ? _b : "", size: "small", onChange: function (e) {
                var newFilter = __assign({}, filters);
                newFilter[e.target.name] = e.target.value;
                setFilters(newFilter);
                useRefresh();
            }, disabled: disabled !== null && disabled !== void 0 ? disabled : false }) }));
};
var CssEditDisableTextField = styled(TextField)(function (_a) {
    var theme = _a.theme;
    return ({
        "& .MuiOutlinedInput-root": {
            borderRadius: "10px",
            // width: "10rem",
            backgroundColor: "#E8E8E8", // Lighter gray
        },
        "& .MuiInputBase-input": {
            opacity: 0.6,
            overflow: "auto",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            color: "#333", // Darker text for better contrast
        },
    });
});
//similar to filter, but without setting session storage - for edit dialog
editComp.DisabletextField = function (_a) {
    var _b;
    var filters = _a.filters, setFilters = _a.setFilters, name = _a.name, label = _a.label, width = _a.width, disabled = _a.disabled, fullWidth = _a.fullWidth, xs = _a.xs, sm = _a.sm, md = _a.md, lg = _a.lg, xl = _a.xl, givenValue = _a.givenValue;
    return (_jsx(Grid, { item: true, xs: xs, sm: sm, md: md, lg: lg, xl: xl, children: _jsx(CssEditDisableTextField, { fullWidth: fullWidth, label: label, id: name, name: name, variant: "outlined", value: givenValue ? givenValue : (_b = filters[name]) !== null && _b !== void 0 ? _b : "", size: "small", onChange: function (e) {
                var newFilter = __assign({}, filters);
                newFilter[e.target.name] = e.target.value;
                setFilters(newFilter);
                useRefresh();
            }, disabled: disabled !== null && disabled !== void 0 ? disabled : false }) }));
};
editComp.searchTextField = function (_a) {
    var name = _a.name, label = _a.label, width = _a.width, disabled = _a.disabled, fullWidth = _a.fullWidth, xs = _a.xs, sm = _a.sm, md = _a.md, lg = _a.lg, xl = _a.xl, value = _a.value, onChange = _a.onChange;
    return (_jsx(Grid, { item: true, xs: xs, sm: sm, md: md, lg: lg, xl: xl, children: _jsx(TextField, { fullWidth: fullWidth, label: label, id: name, name: name, variant: "outlined", value: value, sx: {
                borderRadius: "20px",
                "& .MuiOutlinedInput-root": {
                    height: "2.93em",
                    // boxShadow: '0px 6px 8px gray',
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                    },
                    "& input": {
                        // paddingTop: '1.2em',// Move the text down a bit when editing
                        // fontWeight: 900,
                        fontSize: "1.1em",
                    },
                },
                // '& .MuiInputLabel-root': {
                //     fontSize: '0.8em',
                //     paddingTop: '0.23em',
                //     fontWeight: "900",
                // },
            }, size: "small", onChange: function (e) {
                onChange(e.target.value, name);
                useRefresh();
            }, disabled: disabled !== null && disabled !== void 0 ? disabled : false, InputProps: {
                startAdornment: (_jsx(InputAdornment, { position: "start", children: _jsx(SearchIcon, {}) })),
            } }) }));
};
editComp.datePicker = function (_a) {
    var filters = _a.filters, setFilters = _a.setFilters, name = _a.name, label = _a.label, minWidth = _a.minWidth, minDate = _a.minDate, maxDate = _a.maxDate, size = _a.size, notNeedRequired = _a.notNeedRequired, gridBGColor = _a.gridBGColor, width = _a.width;
    var gridStyle = {};
    if (gridBGColor) {
        gridStyle["backgroundColor"] = gridBGColor;
        gridStyle["padding"] = "0.6rem";
        gridStyle["borderRadius"] = "10px";
    }
    console.log(filters === null || filters === void 0 ? void 0 : filters[name], "==log fnnn");
    return (_jsx(Grid, { item: true, style: gridStyle, children: _jsx(RAForm, { children: _jsx(RADateInput, { source: label, 
                // minDate={minDate ?? null}
                //[back] min and max date not working
                validate: RAminValue(new Date("2024-06-06")), 
                // maxDate={maxDate  ?? null}
                onChange: function (e) {
                    var newFilter = __assign({}, filters);
                    var date = new Date(e.target.value).toLocaleDateString("en-US");
                    if (date == "Invalid Date") {
                        delete newFilter[name];
                    }
                    else {
                        newFilter[name] = date;
                    }
                    setFilters(newFilter);
                    useRefresh();
                }, defaultValue: (filters === null || filters === void 0 ? void 0 : filters[name]) ? new Date(filters[name]) : null, sx: {
                    opacity: 0.6,
                    width: "9em",
                    "& .MuiInputBase-root": {
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                        boxShadow: "0px 6px 8px gray",
                        height: "2.7em",
                        fontWeight: 900,
                        fontSize: "1.1em",
                        "& input": {
                            opacity: 0.7,
                            paddingTop: "1em",
                        },
                    },
                    "& .MuiFormLabel-root": {},
                } }) }) }));
};
var StyledSelect = styled(Select)({
    "& .MuiOutlinedInput-notchedOutline": {
        borderTopLeftRadius: "20px",
        borderTopRightRadius: "20px",
        border: "1px solid #bdbdbd",
        // boxShadow: '0px 6px 8px gray',
    },
    "& .MuiSelect-select": {
        display: "flex",
        alignItems: "center",
        paddingTop: "30px",
    },
    height: "2.93em",
    opacity: 0.6,
});
var StyledInputLabel = styled(InputLabel)({
    // top: "-10px",
    fontSize: "0.8em",
    fontWeight: "900",
    opacity: 0.6,
});
filter.dropDownList = function (_a) {
    var _b, _c;
    var filters = _a.filters, setFilters = _a.setFilters, name = _a.name, label = _a.label, minWidth = _a.minWidth, options = _a.options, formControlSize = _a.formControlSize, notIncludeAllSelection = _a.notIncludeAllSelection, gridBGColor = _a.gridBGColor, xs = _a.xs, sm = _a.sm, md = _a.md, lg = _a.lg, xl = _a.xl;
    var gridStyle = {};
    if (gridBGColor)
        gridStyle["backgroundColor"] = gridBGColor;
    return (_jsx(Grid, { item: true, style: gridStyle, xs: xs, sm: sm, md: md, lg: lg, xl: xl, children: _jsxs(FormControl, { sx: { width: "100%" }, xs: xs, sm: sm, md: md, lg: lg, xl: xl, size: formControlSize === "medium" ? "medium" : "small", children: [_jsx(StyledInputLabel, { id: name, children: label }), _jsxs(StyledSelect, { labelId: name, id: name, value: (_b = filters[name]) !== null && _b !== void 0 ? _b : "", label: label, name: name, variant: "outlined", 
                    /* input={<CssFdropDownList />} */
                    onChange: function (e) {
                        var newFilter = __assign({}, filters);
                        newFilter[e.target.name] = e.target.value;
                        setFilters(newFilter);
                        sessionStorage.setItem("".concat(window.location.pathname, "_filter"), JSON.stringify(newFilter));
                        useRefresh();
                    }, sx: {
                        width: "100%",
                        "& .MuiSelect-select": {
                            fontWeight: 900,
                        },
                    }, children: [!notIncludeAllSelection && (_jsx(MenuItem, { value: "", children: _jsx("em", { children: "---------" }) })), (_c = options === null || options === void 0 ? void 0 : options[name]) === null || _c === void 0 ? void 0 : _c.map(function (el) {
                            return typeof el === "string" ? (_jsx(MenuItem, { value: el, children: el }, "empty")) : (_jsx(MenuItem, { value: el.value, children: el.label || el.name }, el.value));
                        })] })] }) }));
};
var StyledSelectsetting = styled(Select)({
    "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "20px",
        border: "1px solid #bdbdbd",
        boxShadow: "0px 6px 8px gray",
    },
    "& .MuiSelect-select": {
        display: "flex",
        alignItems: "center",
        paddingTop: "12px",
        paddingLeft: "30px",
    },
    width: "9em",
    height: "2.9em",
    opacity: 0.6,
});
var StyledInputLabelsetting = styled(InputLabel)({
    fontSize: "0.8em",
    fontWeight: "900",
    opacity: 0.6,
});
filter.dropDownListSetting = function (_a) {
    var _b, _c;
    var filters = _a.filters, setFilters = _a.setFilters, name = _a.name, label = _a.label, minWidth = _a.minWidth, options = _a.options, formControlSize = _a.formControlSize, notIncludeAllSelection = _a.notIncludeAllSelection, gridBGColor = _a.gridBGColor, xs = _a.xs, sm = _a.sm, md = _a.md, lg = _a.lg, xl = _a.xl;
    var gridStyle = {};
    if (gridBGColor)
        gridStyle["backgroundColor"] = gridBGColor;
    return (_jsx(Grid, { item: true, style: gridStyle, xs: xs, sm: sm, md: md, lg: lg, xl: xl, children: _jsxs(FormControl, { sx: { minWidth: minWidth }, xs: xs, sm: sm, md: md, lg: lg, xl: xl, size: formControlSize === "medium" ? "medium" : "small", children: [_jsx(StyledInputLabel, { id: name, children: label }), _jsxs(StyledSelectsetting, { labelId: name, id: name, value: (_b = filters[name]) !== null && _b !== void 0 ? _b : "", label: label, name: name, variant: "outlined", 
                    /* input={<CssFdropDownList />} */
                    onChange: function (e) {
                        var newFilter = __assign({}, filters);
                        newFilter[e.target.name] = e.target.value;
                        setFilters(newFilter);
                        sessionStorage.setItem("".concat(window.location.pathname, "_filter"), JSON.stringify(newFilter));
                        useRefresh();
                    }, sx: {
                        "& .MuiSelect-select": {
                            width: "100%",
                            fontWeight: 900,
                        },
                    }, children: [!notIncludeAllSelection && (_jsx(MenuItem, { value: "", children: _jsx("em", { children: "---------" }) })), (_c = options === null || options === void 0 ? void 0 : options[name]) === null || _c === void 0 ? void 0 : _c.map(function (el) {
                            return typeof el === "string" ? (_jsx(MenuItem, { value: el, children: el }, "empty")) : (_jsx(MenuItem, { value: el.value, children: el.label || el.name }, el.value));
                        })] })] }) }));
};
filter.onChangeDropDownList = function (_a) {
    var _b, _c;
    var handleOnChange = _a.handleOnChange, filters = _a.filters, name = _a.name, label = _a.label, minWidth = _a.minWidth, options = _a.options, formControlSize = _a.formControlSize, notIncludeAllSelection = _a.notIncludeAllSelection, gridBGColor = _a.gridBGColor, xs = _a.xs, sm = _a.sm, md = _a.md, lg = _a.lg, xl = _a.xl, notOutsideLabel = _a.notOutsideLabel, disabled = _a.disabled;
    var gridStyle = { display: "flex", flexWrap: "wrap", alignItems: "center" };
    if (gridBGColor)
        gridStyle["backgroundColor"] = gridBGColor;
    return (_jsxs(Grid, { item: true, style: gridStyle, xs: xs, sm: sm, md: md, lg: lg, xl: xl, children: [_jsx(Grid, { xs: 4, children: _jsx(Typography, { variant: "body", sx: { fontWeight: "900", opacity: "0.6" }, children: label + ":" }) }), _jsx(Grid, { xs: 8, children: _jsxs(FormControl, { sx: { minWidth: minWidth }, xs: xs, sm: sm, md: md, lg: lg, xl: xl, size: formControlSize === "medium" ? "medium" : "small", children: [_jsx(InputLabel, { id: name, children: notOutsideLabel ? label : "" }), _jsxs(Select, { labelId: name, variant: "outlined", id: name, value: (_b = filters[name]) !== null && _b !== void 0 ? _b : "", label: notOutsideLabel ? label : "", name: name, disabled: disabled, onChange: function (e) {
                                handleOnChange(e.target.value, name);
                                useRefresh();
                            }, sx: {
                                borderRadius: "10px",
                                boxShadow: "0 0 5px Lightgray",
                                width: "17em",
                                overflow: "auto",
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "black",
                                },
                            }, children: [!notIncludeAllSelection && (_jsx(MenuItem, { value: "", children: _jsx("em", { children: "---------" }) })), (_c = options === null || options === void 0 ? void 0 : options[name]) === null || _c === void 0 ? void 0 : _c.map(function (el) {
                                    return typeof el === "string" ? (_jsx(MenuItem, { value: el, children: el }, "empty")) : (_jsx(MenuItem, { value: el.value, children: el.label || el.name }, el.value));
                                })] })] }) })] }));
};
filter.dropDownCheckBoxList = function (_a) {
    var _b, _c;
    var filters = _a.filters, setFilters = _a.setFilters, name = _a.name, label = _a.label, minWidth = _a.minWidth, options = _a.options, formControlSize = _a.formControlSize, setOptionsForSelectAll = _a.setOptionsForSelectAll, givenSelectRenderVal = _a.givenSelectRenderVal;
    var selectRenderVal = givenSelectRenderVal !== null && givenSelectRenderVal !== void 0 ? givenSelectRenderVal : ((filters === null || filters === void 0 ? void 0 : filters[name])
        ? (_b = filters === null || filters === void 0 ? void 0 : filters[name]) === null || _b === void 0 ? void 0 : _b.map(function (filterItem) {
            var _a;
            var matchingObj = (_a = options === null || options === void 0 ? void 0 : options[name]) === null || _a === void 0 ? void 0 : _a.find(function (optionItem) { return optionItem.value == filterItem; });
            return matchingObj
                ? (matchingObj === null || matchingObj === void 0 ? void 0 : matchingObj.name) || (matchingObj === null || matchingObj === void 0 ? void 0 : matchingObj.label)
                : undefined;
        }).join(", ")
        : "");
    return (_jsx(Grid, { item: true, children: _jsxs(FormControl, { sx: { minWidth: minWidth }, size: formControlSize === "medium" ? "medium" : "small", children: [_jsx(InputLabel, { id: name, shrink: true, children: label }), _jsx(Select, { labelId: name, id: name, value: (_c = filters[name]) !== null && _c !== void 0 ? _c : "", renderValue: function () {
                        //showing default value without <MenuItem>
                        return selectRenderVal;
                    }, notched: true, label: label, name: name, sx: { width: "200px", display: "flex", flexWrap: "wrap" }, children: options === null || options === void 0 ? void 0 : options[name].map(function (el, index) {
                        var _a;
                        return (_jsxs(React.Fragment, { children: [_jsx(FormControlLabel, { sx: {
                                        paddingLeft: "10px",
                                        margin: 0,
                                        width: "200px",
                                        "&:hover": {
                                            //hover effects
                                            boxShadow: "inset 200px 200px 200px rgb(245,245,245)",
                                        },
                                    }, value: el === "string" || el === "number" ? el : el.value, control: _jsx(Checkbox, { name: name, checked: (_a = filters[name]) === null || _a === void 0 ? void 0 : _a.includes(el.value), sx: {
                                            color: el.boxColor ? el.boxColor : "secondary",
                                            "&.Mui-checked": el.boxColor
                                                ? { color: el.boxColor }
                                                : { color: "secondary" },
                                        }, onChange: function (e) {
                                            var _a;
                                            var _b, _c;
                                            var newFilter = __assign({}, filters);
                                            var curOptionFilter = newFilter[e.target.name]
                                                ? newFilter[e.target.name]
                                                : []; //[] format
                                            switch (true) {
                                                // deselectAll: empty filters
                                                case e.target.value === "selectAll" &&
                                                    ((_b = filters[name]) === null || _b === void 0 ? void 0 : _b.includes("selectAll")):
                                                    curOptionFilter = [];
                                                    setOptionsForSelectAll(e.target.value);
                                                    break;
                                                // selectAll: push all values into filters
                                                case e.target.value === "selectAll" &&
                                                    !((_c = filters[name]) === null || _c === void 0 ? void 0 : _c.includes("selectAll")):
                                                    curOptionFilter = [];
                                                    options === null || options === void 0 ? void 0 : options[name].map(function (opEl) {
                                                        curOptionFilter.push(opEl.value);
                                                    });
                                                    setOptionsForSelectAll(e.target.value);
                                                    break;
                                                //select/deselect normal values
                                                default:
                                                    if (e.target.checked)
                                                        curOptionFilter.push(parseInt(e.target.value));
                                                    //if is check, push into []
                                                    else if (!e.target.checked) {
                                                        //remove from [] if uncheck
                                                        var indexToRemove = curOptionFilter.indexOf(parseInt(e.target.value));
                                                        if (indexToRemove !== -1)
                                                            curOptionFilter.splice(indexToRemove, 1);
                                                        //remove "selectAll" if uncheck any district
                                                        curOptionFilter = curOptionFilter === null || curOptionFilter === void 0 ? void 0 : curOptionFilter.filter(function (value) { return value !== "selectAll"; });
                                                    }
                                            }
                                            setFilters(__assign(__assign({}, newFilter), (_a = {}, _a[e.target.name] = curOptionFilter, _a)));
                                            sessionStorage.setItem("".concat(window.location.pathname, "_filter"), JSON.stringify(newFilter));
                                            useRefresh();
                                        } }), label: (el === null || el === void 0 ? void 0 : el.label) || (el === null || el === void 0 ? void 0 : el.name), labelPlacement: "end" }), _jsx("br", {})] }, index));
                    }) })] }) }));
};
filter.datePicker = function (_a) {
    var filters = _a.filters, setFilters = _a.setFilters, name = _a.name, label = _a.label, minWidth = _a.minWidth, minDate = _a.minDate, maxDate = _a.maxDate, size = _a.size, notNeedRequired = _a.notNeedRequired, gridBGColor = _a.gridBGColor, width = _a.width;
    var gridStyle = {};
    if (gridBGColor) {
        gridStyle["backgroundColor"] = gridBGColor;
        gridStyle["padding"] = "0.6rem";
        gridStyle["borderRadius"] = "10px";
    }
    console.log(filters === null || filters === void 0 ? void 0 : filters[name], "==log fnnn");
    return (_jsx(Grid, { item: true, style: gridStyle, children: _jsx(RAForm, { children: _jsx(RADateInput, { source: label, 
                // minDate={minDate ?? null}
                //[back] min and max date not working
                validate: RAminValue(new Date("2024-06-06")), 
                // maxDate={maxDate  ?? null}
                onChange: function (e) {
                    var newFilter = __assign({}, filters);
                    var date = new Date(e.target.value).toLocaleDateString("en-US");
                    if (date == "Invalid Date") {
                        delete newFilter[name];
                    }
                    else {
                        newFilter[name] = date;
                    }
                    setFilters(newFilter);
                    sessionStorage.setItem("".concat(window.location.pathname, "_filter"), JSON.stringify(newFilter));
                    useRefresh();
                }, defaultValue: (filters === null || filters === void 0 ? void 0 : filters[name]) ? new Date(filters[name]) : null }) }) }));
};
filter.checkbox = function (_a) {
    var _b, _c;
    var filters = _a.filters, setFilters = _a.setFilters, name = _a.name, label = _a.label, boxColor = _a.boxColor;
    return (_jsx(Grid, { item: true, children: _jsx(FormControlLabel, { value: (_b = filters[name]) !== null && _b !== void 0 ? _b : false, control: _jsx(Checkbox, { name: name, checked: (_c = filters[name]) !== null && _c !== void 0 ? _c : false, onChange: function (e) {
                    var newFilter = __assign({}, filters);
                    newFilter[e.target.name] = e.target.checked;
                    setFilters(newFilter);
                    sessionStorage.setItem("".concat(window.location.pathname, "_filter"), JSON.stringify(newFilter));
                    useRefresh();
                } }), label: label, labelPlacement: "start" }) }));
};
filter.checkboxForArrayFilters = function (_a) {
    var _b;
    var filters = _a.filters, name = _a.name, label = _a.label, boxColor = _a.boxColor, onChangeMethod = _a.onChangeMethod, givenValue = _a.givenValue, options = _a.options, labelPlacement = _a.labelPlacement, marginRight = _a.marginRight, paddingLeft = _a.paddingLeft;
    return (_jsx(Grid, { item: true, style: {
            // backgroundColor: "green",
            paddingLeft: paddingLeft !== null && paddingLeft !== void 0 ? paddingLeft : "1rem",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
        }, children: _jsx(FormControlLabel, { renderValue: function () {
                var _a;
                //showing default value without <MenuItem>
                return filters[name]
                    ? (_a = filters === null || filters === void 0 ? void 0 : filters[name]) === null || _a === void 0 ? void 0 : _a.map(function (filterItem) {
                        var _a;
                        var matchingObj = (_a = options === null || options === void 0 ? void 0 : options[name]) === null || _a === void 0 ? void 0 : _a.find(function (optionItem) { return optionItem.value == filterItem; });
                        return matchingObj
                            ? matchingObj.name || matchingObj.label
                            : undefined;
                    }).join(", ")
                    : "";
            }, sx: { marginRight: marginRight !== null && marginRight !== void 0 ? marginRight : "5px" }, control: _jsx(Checkbox, { name: name, checked: (_b = filters[name]) === null || _b === void 0 ? void 0 : _b.includes(givenValue), sx: {
                    color: boxColor ? boxColor : "secondary",
                    "&.Mui-checked": boxColor
                        ? { color: boxColor }
                        : { color: "secondary" },
                }, onChange: function (e) {
                    onChangeMethod(givenValue, name, e.target.checked);
                    useRefresh();
                } }), label: label, labelPlacement: labelPlacement !== null && labelPlacement !== void 0 ? labelPlacement : "end" }) }));
};
filter.numberField = function (_a) {
    var _b;
    var filters = _a.filters, setFilters = _a.setFilters, name = _a.name, label = _a.label, width = _a.width, allowNegative = _a.allowNegative;
    return (_jsx(Grid, { item: true, children: _jsx(TextField, { label: label, id: name, name: name, value: (_b = filters[name]) !== null && _b !== void 0 ? _b : 0, size: "small", style: { width: width }, onChange: function (e) {
                if (!isNaN(e.target.value) ||
                    !e.target.value ||
                    (allowNegative && e.target.value === "-")) {
                    var newFilter = __assign({}, filters);
                    newFilter[e.target.name] = Number(e.target.value);
                    setFilters(newFilter);
                    sessionStorage.setItem("".concat(window.location.pathname, "_filter"), JSON.stringify(newFilter));
                    useRefresh();
                }
            } }) }));
};
filter.textField = function (_a) {
    var _b;
    var filters = _a.filters, setFilters = _a.setFilters, name = _a.name, label = _a.label, width = _a.width, disabled = _a.disabled, fullWidth = _a.fullWidth, xs = _a.xs, sm = _a.sm, md = _a.md, lg = _a.lg, xl = _a.xl, givenValue = _a.givenValue, extraPath = _a.extraPath;
    return (_jsx(Grid, { item: true, xs: xs, sm: sm, md: md, lg: lg, xl: xl, children: _jsx(TextField, { fullWidth: fullWidth, label: label, id: name, name: name, variant: "outlined", value: givenValue ? givenValue : (_b = filters[name]) !== null && _b !== void 0 ? _b : "", size: "small", onChange: function (e) {
                var newFilter = __assign({}, filters);
                newFilter[e.target.name] = e.target.value;
                setFilters(newFilter);
                sessionStorage.setItem("".concat(window.location.pathname).concat(extraPath !== null && extraPath !== void 0 ? extraPath : "", "_filter"), JSON.stringify(newFilter));
                useRefresh();
            }, disabled: disabled !== null && disabled !== void 0 ? disabled : false, sx: {
                width: "100%",
                opacity: 0.6,
                "& .MuiOutlinedInput-root": {
                    height: "2.93em",
                    borderTopLeftRadius: "20px",
                    borderTopRightRadius: "20px",
                    // boxShadow: '0px 6px 8px gray',
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                    },
                    "& input": {
                        paddingTop: "1.2em", // Move the text down a bit when editing
                        fontWeight: 900,
                        fontSize: "1.1em",
                    },
                },
                "& .MuiInputLabel-root": {
                    fontSize: "0.8em",
                    paddingTop: "0.23em",
                    fontWeight: "900",
                },
            } }) }));
};
filter.onChangeTextField = function (_a) {
    var _b;
    var filters = _a.filters, handleOnChange = _a.handleOnChange, name = _a.name, label = _a.label, width = _a.width, disabled = _a.disabled, fullWidth = _a.fullWidth, xs = _a.xs, sm = _a.sm, md = _a.md, lg = _a.lg, xl = _a.xl, givenValue = _a.givenValue, notOutsideLabel = _a.notOutsideLabel, type = _a.type, isPW = _a.isPW, handleClickShowPassword = _a.handleClickShowPassword;
    return (_jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", alignItems: "center", xs: xs, sm: sm, md: md, lg: lg, xl: xl, children: [_jsx(Grid, { xs: 4, children: _jsx(Typography, { variant: "body", sx: { fontWeight: "900", opacity: "0.6" }, children: label + ":" }) }), _jsx(Grid, { xs: 8, children: _jsx(TextField, { type: type !== null && type !== void 0 ? type : "", fullWidth: fullWidth, label: notOutsideLabel ? label : "", id: name, variant: "outlined", name: name, value: givenValue ? givenValue : (_b = filters[name]) !== null && _b !== void 0 ? _b : "", size: "small", sx: {
                        width: "10em",
                        "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            boxShadow: "0 0 5px Lightgray",
                            width: "17em",
                        },
                    }, onChange: function (e) {
                        handleOnChange(e.target.value, name);
                        useRefresh();
                    }, disabled: disabled !== null && disabled !== void 0 ? disabled : false, InputProps: {
                        endAdornment: isPW ? (_jsx(InputAdornment, { children: _jsx(IconButton, { "aria-label": "toggle password visibility", onClick: function () {
                                    handleClickShowPassword(type, name);
                                }, edge: "end", children: type === "password" ? _jsx(VisibilityOff, {}) : _jsx(Visibility, {}) }) })) : (_jsx(_Fragment, {})),
                    } }) })] }));
};
var useStylesForPointer = makeStyles({
    popOverRoot: {
        pointerEvents: "none",
    },
});
var NestedDropDownWithHover = function (props) {
    var currentlyHovering = false;
    var styles = useStylesForPointer();
    var _a = useState(null), anchorEl = _a[0], setAnchorEl = _a[1];
    var open = Boolean(anchorEl);
    var arrowTextDecoStyle = "lightblue";
    var textDecoStyle = "none";
    var handleMouseHover = function (event) {
        if (anchorEl !== event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    };
    // click on link title (big title)
    var handleClick = function (index) { };
    function handleHover() {
        currentlyHovering = true;
    }
    var handleClose = function () {
        setAnchorEl(null);
    };
    function handleCloseHover() {
        currentlyHovering = false;
        setTimeout(function () {
            if (!currentlyHovering) {
                handleClose();
            }
        }, 50);
    }
    // click drop down menu items
    function handleDropDownItemClick(dropDownItemIndex) { }
    // StyledMenu
    var StyledMenu = styled(function (props) { return (_jsx(Menu, __assign({ elevation: 0, 
        // anchorOrigin={{
        //   vertical: 'top',
        //   horizontal: 'left',
        //   // horizontal: props.MenuListProps.boxPos
        // }}
        transformOrigin: {
            vertical: "top",
            horizontal: "left",
            // horizontal: props.MenuListProps.boxPos
        } }, props))); })(function (_a) {
        var theme = _a.theme;
        return ({
            "& .MuiPaper-root": {
                borderRadius: 6,
                marginTop: theme.spacing(1),
                minWidth: 180,
                // color: 'rgb(0,0,0)',
                // backgroundColor: 'yellow',
                //color:
                //theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
                // boxShadow:
                //   'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
                // '& .MuiMenu-list': {
                //   padding: '4px 0',
                // },
                "& .MuiMenuItem-root": {
                    "& .MuiSvgIcon-root": {
                        fontSize: 18,
                        color: theme.palette.text.secondary,
                        marginRight: theme.spacing(1.5),
                    },
                    "&:active": {
                        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
                    },
                },
            },
        });
    });
    return (_jsxs("div", { children: [_jsxs(Button
            // aria-controls={open ? 'demo-customized-menu' : undefined}
            // aria-owns={anchorEl ? "demo-customized-menu" : undefined}
            , { "aria-owns": anchorEl ? "simple-menu" : undefined, "aria-haspopup": "true", 
                // aria-expanded={open ? 'true' : undefined}
                // variant="contained"
                disableElevation: true, style: { color: "black", cursor: "pointer" }, onClick: function () {
                    handleClick(props.index);
                }, onMouseOver: handleMouseHover, onMouseLeave: handleCloseHover, children: [_jsx("span", { style: { textDecoration: textDecoStyle, paddingRight: "0.3rem" }, children: props.name }), _jsx("span", { style: { color: arrowTextDecoStyle, fontSize: "1.5rem" }, children: "\u25BE" })] }), _jsx(StyledMenu
            // id="demo-customized-menu"
            , { 
                // id="demo-customized-menu"
                id: "simple-menu", MenuListProps: {
                    // 'aria-labelledby': 'demo-customized-button',
                    boxPos: props.boxPos,
                    style: { pointerEvents: "auto" },
                    onMouseEnter: handleHover,
                    onMouseLeave: handleCloseHover,
                }, anchorEl: anchorEl, open: open, onClose: handleClose, anchorOrigin: { horizontal: "left", vertical: "bottom" }, PopoverClasses: {
                    root: styles.popOverRoot,
                }, children: props.lst.map(function (item, index) {
                    _jsxs(MenuItem, { children: [item, _jsx(StyledMenu
                            // id="demo-customized-menu"
                            , { 
                                // id="demo-customized-menu"
                                id: "simple-menu", MenuListProps: {
                                    // 'aria-labelledby': 'demo-customized-button',
                                    boxPos: props.boxPos,
                                    style: { pointerEvents: "auto" },
                                    onMouseEnter: handleHover,
                                    onMouseLeave: handleCloseHover,
                                }, anchorEl: anchorEl, open: open, onClose: handleClose, anchorOrigin: { horizontal: "left", vertical: "bottom" }, PopoverClasses: {
                                    root: styles.popOverRoot,
                                }, children: props.lst.map(function (item, index) {
                                    return (_jsx(MenuItem, { disableRipple: true, onClick: function () {
                                            handleDropDownItemClick(index);
                                        }, children: item }, index));
                                }) })] });
                }) })] }));
};
export { general, dialog, dashboard, filter, editComp, NestedDropDownWithHover, };
