import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useDataProvider, useTranslate, useNotify, useRefresh, } from 'react-admin';
import AllOrganizations from './AllOrganization';
import RevenueReport from './RevenueReport';
import YourDashboard from './YourDashborad';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { acl } from '../utils/dummyData';
var Dashboard = function (props) {
    var translate = useTranslate();
    var notify = useNotify();
    var refresh = useRefresh();
    var _a = useState(acl), acessControlList = _a[0], setAcessControlList = _a[1];
    var _b = useState(""), userOrg = _b[0], setUserOrg = _b[1];
    var dataProvider = useDataProvider();
    useEffect(function () {
        // const timer = setTimeout(() => {
        //     console.log("update")
        //     window.location.reload();
        // }, 20);
        var _a;
        // // Cleanup function to clear the timer
        // return () => clearTimeout(timer);
        var cachedAuth = (_a = JSON.parse(localStorage.getItem('auth'))) !== null && _a !== void 0 ? _a : {};
        if (cachedAuth.id) {
            dataProvider.getOneWithoutDataJSON("users", { id: cachedAuth.id })
                .then(function (data) {
                var _a, _b, _c, _d, _e;
                var newACList = ((_a = data === null || data === void 0 ? void 0 : data.profile) === null || _a === void 0 ? void 0 : _a.ACL) || {};
                setAcessControlList(newACList);
                localStorage.setItem("userACL", JSON.stringify(newACList)); // Store ACL in local storage
                setUserOrg((_c = (_b = data.profile) === null || _b === void 0 ? void 0 : _b.info) === null || _c === void 0 ? void 0 : _c['current_organization'][1]);
                localStorage.setItem("appBarOrg", (_e = (_d = data.profile) === null || _d === void 0 ? void 0 : _d.info) === null || _e === void 0 ? void 0 : _e['current_organization'][1]);
                refresh();
            })
                .catch(function (error) {
                console.error(error);
                notify(translate('error.ACL.getACLFailed'), {
                    type: 'error',
                });
            });
            dataProvider.ShowUrOrg({ id: cachedAuth.id })
                .then(function (data) {
                setUserOrg(data.data.profile.info.current_organization[1]);
            })
                .catch(function (error) {
                console.error(error);
                notify(translate('error.organization.getUserOrgFailed'), {
                    type: 'error',
                });
            });
            // dataProvider.ShowUrOrg({ id: cachedAuth.id })
            //     .then((data) => {
            //         setUserOrg(data.data.orgName);
            //     })
            //     .catch((error) => {
            //         console.error(error);
            //         notify(
            //             translate('error.organization.getUserOrgFailed'),
            //             {
            //                 type: 'error',
            //             }
            //         );
            //     });
            // window.location.reload();
        }
    }, []);
    var _c = useState(0), selectedTab = _c[0], setSelectedTab = _c[1];
    return (_jsxs(Box, { children: [acessControlList.dashboard_admin.view ? (_jsxs(Tabs, { value: selectedTab, onChange: function (event, newValue) { return setSelectedTab(newValue); }, sx: {
                    backgroundColor: "#1561a5",
                    borderRadius: '8px',
                    marginTop: '1.3rem',
                    marginLeft: '0.75em',
                    marginRight: '0.75em',
                }, TabIndicatorProps: {
                    style: {
                        backgroundColor: '#03a9f4',
                        height: 6,
                        borderRadius: '1.5px',
                    },
                }, children: [_jsx(Tab, { label: translate('pages.dashboard.title.revenueReport'), sx: {
                            color: 'white',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            '&.Mui-selected': {
                                color: 'white',
                            },
                        } }), _jsx(Tab, { label: translate('pages.dashboard.title.allOrganizations'), sx: {
                            color: 'white',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            '&.Mui-selected': {
                                color: 'white',
                            },
                        } }), _jsx(Tab, { label: "".concat(translate('pages.dashboard.title.yourOrganization')), sx: {
                            color: 'white',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            '&.Mui-selected': {
                                color: 'white',
                            },
                        } })] })) : acessControlList.dashboard_normal.view ? (_jsx(Tabs, { value: 2, sx: {
                    backgroundColor: "#1561a5",
                    borderRadius: '8px',
                    marginTop: '1.3rem',
                    marginLeft: '0.75em',
                    marginRight: '0.75em',
                }, TabIndicatorProps: {
                    style: {
                        backgroundColor: '#03a9f4',
                        height: 6,
                        borderRadius: '1.5px',
                    },
                }, children: _jsx(Tab, { label: "".concat(translate('pages.dashboard.title.yourOrganization')), sx: {
                        color: 'white',
                        fontWeight: 'bold',
                        textTransform: 'none',
                        '&.Mui-selected': {
                            color: 'white',
                        },
                    } }) })) : null, _jsx(Box, { sx: { mt: 2 }, children: acessControlList.dashboard_admin.view ? (_jsxs(_Fragment, { children: [selectedTab === 0 && _jsx(RevenueReport, {}), selectedTab === 1 && _jsx(AllOrganizations, {}), selectedTab === 2 && _jsx(YourDashboard, {})] })) : acessControlList.dashboard_normal.view ? (_jsx(YourDashboard, {})) : null })] }));
};
export default Dashboard;
