var styles = {
    centerContainer: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
    },
    headerText: {
        margin: 0,
        padding: 0,
        color: "#183e88",
        fontWeight: 1000,
    },
    infoTextDiv: {
        // display: "flex",
        // flexWrap: "wrap",
        margin: 0,
        padding: 0,
        width: "25%",
        backgroundColor: "#e8f6f8",
    },
    infoTextTitle: {
        margin: 0,
        color: "grey",
        fontSize: "0.7rem",
        padding: "0 0.5rem",
    },
    infoText: {
        margin: 0,
        fontSize: "0.8rem",
        fontWeight: "bold",
        color: "black",
        padding: "0 0.5rem",
    },
    // mid section
    midSecTextDiv: {
        // display: "flex",
        // flexWrap: "wrap",
        margin: "0 0.5rem",
        padding: 0,
        width: "49%",
        // backgroundColor: "red",
    },
    midSecTitle: {
        margin: 0,
        padding: 0,
        paddingTop: 10,
        // fontWeight: "bold",
        color: "#183e88",
        borderBottom: "1px solid #183e88",
        fontWeight: 1000,
    },
    midSecText: {
        margin: 0,
        padding: 0,
        color: "black",
        fontSize: "0.8rem",
    },
    confLvlText: {
        margin: 0,
        padding: 0,
    },
    // eye img section
    eyeSecTextDiv: {
        margin: "0 0.5rem",
        padding: 0,
        width: "49%",
        marginBottom: "1rem",
        display: "flex",
        flexWrap: "wrap"
    },
    eyeSecCaptionDiv: {
        display: "flex",
        flexWrap: "wrap",
        // margin: "3rem 3rem",
        backgroundColor: "",
    },
    eyeTextDiv: {
        // display: "flex",
        // flexWrap: "wrap",
        margin: "0 0.5rem",
        padding: 0,
        width: "49%",
        // backgroundColor: "red",
    },
    eyeSecTitle: {
        margin: 0,
        marginBottom: "0.5rem",
        padding: 0,
        fontWeight: 1000,
        color: "#183e88",
        borderBottom: "1px solid #183e88",
        width: "100%",
    },
    eyeSecText: {
        padding: 0,
        color: "black",
    },
};
export { styles };
