var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var dbDateHelperMMDD = function (dateStr) {
    var date = new Date(dateStr);
    var month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    var day = String(date.getDate()).padStart(2, '0');
    return "".concat(day, "/").concat(month);
};
var dbDateHelper = function (dateStr) {
    var date = new Date(dateStr);
    var formattedDate = date.toLocaleDateString();
    return formattedDate;
};
var dbDateHelperWithHHMM = function (dateStr) {
    var date = new Date(dateStr);
    var formattedTime = date.toLocaleDateString(undefined, {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false // Set to true for 12-hour format
    });
    return formattedTime;
};
var splitDateTimeHelper = function (dateStr) {
    //original dateStr: "7/16/2024, 4:55:23 PM"
    var dateParts = dateStr.split(",");
    var formattedDate = dateParts[0];
    return formattedDate;
};
var blueIDHelper = function (idStr) {
    if (idStr) {
        var asterisks = "*".repeat(Math.min(4, idStr.length));
        var modifiedString = asterisks + idStr.substring(4);
        return modifiedString;
    }
    return idStr;
};
var subjectIdHelper = function (idStr) {
    if (idStr) {
        var modifiedString = "...." + idStr.slice(-4);
        return modifiedString;
    }
    return idStr;
};
var pdfSubjectIdHelper = function (idStr) {
    if (idStr) {
        var modifiedString = idStr.slice(-4);
        return modifiedString;
    }
    return idStr;
};
var longNameHelper = function (longStr) {
    if (longStr && (longStr === null || longStr === void 0 ? void 0 : longStr.length) > 26) {
        var modifiedString = "...." + longStr.slice(-4);
        return modifiedString;
    }
    return longStr;
};
var imgSrcPathHelper = function (src) {
    if (src) {
        var modifiedString = src.replace(/\\/g, "/");
        console.log('==log modifiedString', modifiedString);
        return modifiedString;
    }
    return src;
};
var dragImgNameHelper = function (str) {
    if (str && (str === null || str === void 0 ? void 0 : str.length) > 24) {
        return "..." + str.slice(-21);
    }
    return str;
    // return "dragImgNameHelperdragImgNameHelperdragImgNameHelper".slice(-21)
};
var emptyFunc = function () {
};
var secToMin = function (secondsStr) {
    var minutes = Math.floor(secondsStr / 60);
    var seconds = secondsStr % 60;
    var timeString = "".concat(minutes, " min \n    ").concat(seconds > 0 ? seconds + " sec" : "", " ");
    return timeString;
};
//flatten nested obj with _ (e.g. image: {x: 1} -> image_x: 1)
var flattenObjectWithUnderscores = function (obj, parentKey) {
    if (parentKey === void 0) { parentKey = ''; }
    var result = {};
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
            var newKey = parentKey ? "".concat(parentKey, "_").concat(key) : key;
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                Object.assign(result, flattenObjectWithUnderscores(obj[key], newKey));
            }
            else {
                result[newKey] = obj[key];
            }
        }
    }
    console.log(result, '==result');
    return result;
};
//exclude (delete) array of key from object
var excludeFromObj = function (obj, excludeArr) {
    var newObj = __assign({}, obj);
    excludeArr.forEach(function (key) {
        if (newObj.hasOwnProperty(key)) {
            delete newObj[key];
        }
    });
    return newObj;
};
export { dbDateHelper, dbDateHelperWithHHMM, blueIDHelper, imgSrcPathHelper, splitDateTimeHelper, subjectIdHelper, emptyFunc, secToMin, dragImgNameHelper, flattenObjectWithUnderscores, excludeFromObj, longNameHelper, pdfSubjectIdHelper, dbDateHelperMMDD, };
