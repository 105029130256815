import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslate, } from "react-admin";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import PageviewIcon from "@mui/icons-material/Pageview";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { dbDateHelper } from "../utils/helper";
import { NORMAL_GRID_SPACE, } from "../styles/styleConstants.data";
import "../styles/global.module.css";
var Spacer = function () { return _jsx(Box, { mb: 1, children: "\u00A0" }); };
var RowItem = function (_a) {
    var leftText = _a.leftText, rightText = _a.rightText;
    return (_jsxs(Grid, { className: "small-bottom-margin", item: true, xs: 12, display: "flex", alignContent: "center", justifyContent: "space-between", children: [_jsx(Typography, { variant: "body", children: leftText }), _jsx(Typography, { variant: "body", children: rightText })] }));
};
var RowItemTitle = function (_a) {
    var title = _a.title;
    return (_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { className: "normal-text", children: title }) }));
};
var SelectedRowLeftColumn = function (_a) {
    var AccessControlList = _a.AccessControlList, selectedRow = _a.selectedRow, setSelectedRow = _a.setSelectedRow, data = _a.data, openExamEditDialog = _a.openExamEditDialog, setDialogOpened = _a.setDialogOpened, closeLeftPanel = _a.closeLeftPanel, page = _a.page, handlePreviewExamDate = _a.handlePreviewExamDate, params = _a.params;
    var translate = useTranslate();
    var navigate = useNavigate();
    console.log(selectedRow, "==selectedRow 123");
    var _b = useState(false), canEditOrg = _b[0], setCanEditOrg = _b[1];
    //set if can edit org
    useEffect(function () {
        var _a, _b, _c;
        var appBarOrg = (_a = localStorage.getItem("appBarOrg")) !== null && _a !== void 0 ? _a : "";
        if (((_b = AccessControlList === null || AccessControlList === void 0 ? void 0 : AccessControlList.subject_admin) === null || _b === void 0 ? void 0 : _b.update) || (((_c = AccessControlList === null || AccessControlList === void 0 ? void 0 : AccessControlList.subject_normal) === null || _c === void 0 ? void 0 : _c.update) && (selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.orgName) == appBarOrg)) {
            setCanEditOrg(true);
        }
    }, [selectedRow]);
    return (_jsxs(Grid, { container: true, direction: "row", rowGap: NORMAL_GRID_SPACE, children: [_jsx(Grid, { xs: 12, children: _jsx(SubjectTitle, { canEditOrg: canEditOrg, setCanEditOrg: setCanEditOrg, selectedRow: selectedRow, AccessControlList: AccessControlList, setSelectedRow: setSelectedRow, navigate: navigate, setDialogOpened: setDialogOpened, closeLeftPanel: closeLeftPanel, page: page }) }), _jsxs(Grid, { className: "small-text", xs: 12, padding: 2, sx: {
                    backgroundColor: "white",
                    // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    border: "1px #ddd solid",
                    borderRadius: "15px",
                }, children: [_jsx(Grid, { className: "normal-bottom-margin", children: _jsx(RowItemTitle, { title: translate("pages.subject.fields.info") }) }), _jsx(Grid, { className: "small-bottom-margin", children: _jsx(RowItem, { leftText: translate("pages.subject.fields.customId"), 
                            // rightText={blueIDHelper(selectedRow.customId) || "_ _ _ _"}
                            rightText: selectedRow.customId || "_ _ _ _" }) }), _jsx(Grid, { className: "small-bottom-margin", children: _jsx(RowItem, { leftText: translate("pages.userList.fields.organization"), rightText: (selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.orgName) || "_ _ _ _" }) }), _jsx(Grid, { className: "small-bottom-margin", children: _jsx(RowItem, { leftText: translate("pages.subject.fields.lastname"), rightText: (selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.lastname) || "_ _ _ _" }) }), _jsx(Grid, { className: "small-bottom-margin", children: _jsx(RowItem, { leftText: translate("pages.subject.fields.firstname"), rightText: (selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.firstname) || "_ _ _ _" }) }), _jsx(Grid, { className: "small-bottom-margin", children: _jsx(RowItem, { leftText: translate("pages.subject.fields.gender"), rightText: (selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.gender) || "_ _ _ _" }) }), _jsx(Grid, { className: "small-bottom-margin", children: _jsx(RowItem, { leftText: translate("pages.subject.fields.educationLevel"), rightText: (selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.educationLevel) || "_ _ _ _" }) }), _jsx(Grid, { className: "small-bottom-margin", children: _jsx(RowItem, { leftText: translate("pages.subject.fields.dateOfBirth"), rightText: dbDateHelper(selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.dateOfBirth) || "_ _ _ _" }) })] }), _jsxs(Grid, { padding: 2, xs: 12, sx: {
                    backgroundColor: "white",
                    // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    border: "1px #ddd solid",
                    borderRadius: "15px",
                }, children: [_jsxs(Grid, { className: "normal-bottom-margin", display: "flex", flexWrap: "wrap", children: [_jsx(RowItemTitle, { title: translate("pages.exam.allExamDate") }), _jsx(Box, { display: "flex", justifyContent: "flex-end", width: "100%", children: page === "exams" && (_jsxs(Box, { sx: {
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer",
                                    }, onClick: openExamEditDialog, children: [_jsx(AddBoxIcon, { color: "primary", sx: { marginRight: "0.3em", fontSize: "1.2em" } }), _jsx(Typography, { className: "small-text large-bold", color: "primary", children: translate("pages.exam.dialog.add_new_exam") })] })) })] }), data &&
                        data.map(function (el, idx) {
                            console.log("==data for dates", data);
                            return (_jsx(RowItem, { leftText: dbDateHelper(el.exam_date), rightText: _jsx(Grid, { style: { backgroundColor: "", color: "#487ca8" }, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", children: page === "exams" && (_jsx(PageviewIcon, { onClick: function () {
                                            handlePreviewExamDate(el);
                                        } })) }) }));
                        })] })] }));
};
var SubjectTitle = function (_a) {
    var AccessControlList = _a.AccessControlList, setSelectedRow = _a.setSelectedRow, selectedRow = _a.selectedRow, navigate = _a.navigate, setDialogOpened = _a.setDialogOpened, closeLeftPanel = _a.closeLeftPanel, page = _a.page, canEditOrg = _a.canEditOrg, setCanEditOrg = _a.setCanEditOrg;
    var translate = useTranslate();
    return (_jsxs(Grid, { container: true, alignItems: "flex-start", justifyContent: "space-between", children: [_jsx(Grid, { children: _jsx(Typography, { className: "normal-text large-bold", children: translate("pages.subject.ViewSubject") }) }), _jsxs(Grid, { spacing: 4, display: "flex", children: [canEditOrg && (_jsxs(Grid, { xs: 6, className: "small-text normal-bold", sx: {
                            display: "flex",
                            alignItems: "center",
                            color: "#1976d2",
                            cursor: "pointer",
                            borderRadius: "5px",
                        }, onClick: function () {
                            setDialogOpened(true);
                        }, children: [_jsx(EditIcon, { className: "editIcon", sx: { marginRight: 0.5 } }), _jsx("span", { className: "editText", children: translate("dialog.Edit") })] })), _jsxs(Grid, { xs: 6, className: "small-text normal-bold", sx: {
                            display: "flex",
                            alignItems: "center",
                            color: "#1976d2",
                            cursor: "pointer",
                            borderRadius: "5px",
                        }, onClick: function () {
                            setSelectedRow(false);
                            navigate("/admin/subjects");
                            if (closeLeftPanel) {
                                closeLeftPanel();
                            }
                        }, children: [_jsx(CloseIcon, { className: "closeIcon", sx: { marginRight: 0.5 } }), _jsx("span", { className: "closeText", children: translate("common.close") })] })] })] }));
};
export default SelectedRowLeftColumn;
