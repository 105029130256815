var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
// @ts-nocheck
import { EyeMuiChoices } from "../../Params";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
var analyzedResultTextCases = function (_a) {
    var result = _a.result, setFunc = _a.setFunc;
    var resultInfos = [
        { type: "both", text: "Both the right eye and the left eye can be analyzed.", bgColor: "green", icon: CheckCircleIcon },
        { type: "onlyright", text: "(✓RE ✘LE )The left eye cannot be analyzed due to the absence of macula-centered image and ungradable disc-centered image.", bgColor: "#ecaa00", icon: WarningIcon },
        { type: "onlyleft", text: "(✘RE ✓LE )The right eye cannot be analyzed due to the absence of macula-centered image and ungradable disc-centered image.", bgColor: "#ecaa00", icon: WarningIcon },
        { type: "none", text: "Neither the right eye nor the left eye can be analyzed. Please upload another set of images.", bgColor: "#d93d3d", icon: ErrorIcon },
        { type: "toomuch", text: "Only one gradable Macula-centered of left or right eye and one gradable Disc-centered of left or right eye will be use for analyze. Please update the images.", bgColor: "#d93d3d", icon: ErrorIcon },
    ];
    switch (result) {
        case "both":
            setFunc(resultInfos[0]);
            break;
        case "onlyright":
            setFunc(resultInfos[1]);
            break;
        case "onlyleft":
            setFunc(resultInfos[2]);
            break;
        case "none":
            setFunc(resultInfos[3]);
            break;
        case "toomuch":
            setFunc(resultInfos[4]);
            break;
    }
};
var updateAnalyzedResultTextCases = function (givenImgRow, setAnalyzedResultInfo) {
    console.log(givenImgRow, '==givenImgRow');
    // let imgCases = { left: false, right: false }
    var checkGradResult = { leftCen: 0, rightCen: 0, leftNotCen: 0, rightNotCen: 0 };
    checkGradResult = __assign({}, checkGradResult);
    var newGivenImgRow = __spreadArray([], givenImgRow, true);
    newGivenImgRow === null || newGivenImgRow === void 0 ? void 0 : newGivenImgRow.map(function (el, idx) {
        if (el.gradable) {
            if (el.right && el.center) {
                checkGradResult.rightCen += 1;
            }
            if (!el.right && el.center) {
                checkGradResult.leftCen += 1;
            }
            if (el.right && !el.center) {
                checkGradResult.rightNotCen += 1;
            }
            if (!el.right && !el.center) {
                checkGradResult.leftNotCen += 1;
            }
        }
    });
    var textType;
    switch (true) {
        case (checkGradResult.leftCen > 1 || checkGradResult.leftNotCen > 1 || checkGradResult.rightCen > 1 || checkGradResult.rightNotCen > 1):
            analyzedResultTextCases({ result: "toomuch", setFunc: setAnalyzedResultInfo });
            textType = null;
            break;
        case checkGradResult.leftCen == 1 && checkGradResult.leftNotCen == 1 && checkGradResult.rightCen == 1 && checkGradResult.rightNotCen == 1:
            textType = EyeMuiChoices[2].label;
            analyzedResultTextCases({ result: "both", setFunc: setAnalyzedResultInfo });
            break;
        case checkGradResult.leftCen == 1 && checkGradResult.leftNotCen == 1:
            textType = EyeMuiChoices[0].label;
            analyzedResultTextCases({ result: "onlyleft", setFunc: setAnalyzedResultInfo });
            break;
        case checkGradResult.rightCen == 1 && checkGradResult.rightNotCen == 1:
            textType = EyeMuiChoices[1].label;
            analyzedResultTextCases({ result: "onlyright", setFunc: setAnalyzedResultInfo });
            break;
        default:
            analyzedResultTextCases({ result: "none", setFunc: setAnalyzedResultInfo });
            textType = null;
            break;
    }
    return textType;
};
//remove duplicate cmt
var removeDuplicateCommentRows = function (givenComments) {
    var comments = (JSON.parse(JSON.stringify(givenComments)));
    comments = comments.reverse();
    if (comments.length > 0) {
        // Create a map to store the latest comment for each unique id
        var uniqueCommentsMap_1 = new Map();
        // Iterate through the comments array to update the uniqueCommentsMap
        comments.forEach(function (comment) {
            // Check if the comment id already exists in the map
            if (uniqueCommentsMap_1.has(comment.id)) {
                // Compare the dates and update if the current comment is newer
                if (comment.date > uniqueCommentsMap_1.get(comment.id).date) {
                    uniqueCommentsMap_1.set(comment.id, comment);
                }
            }
            else {
                uniqueCommentsMap_1.set(comment.id, comment);
            }
        });
        // Get the unique and latest comments from the map
        var uniqueComments = Array.from(uniqueCommentsMap_1.values());
        return uniqueComments;
    }
    return comments;
};
export { updateAnalyzedResultTextCases, removeDuplicateCommentRows, };
