import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography, Grid, CircularProgress } from '@mui/material';
import { secToMin } from '../../utils/helper';
var styles = {
    container: {
        // position: 'absolute',
        // bottom: 50,
        // right: 50,
        margin: "2rem",
        width: '200px',
        height: '200px',
        backgroundColor: '#f0f0f0',
        // zIndex: 9999999,
    }
};
var AnalyzeAIBox = function (_a) {
    var loadingFPAITime = _a.loadingFPAITime;
    return (_jsx(Grid, { item: true, display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center", style: styles.container, children: _jsxs(Grid, { item: true, xs: 12, style: { backgroundColor: "" }, children: [_jsx(Typography, { align: 'center', children: _jsx(CircularProgress, { color: "grey", size: 44 }) }), _jsx(Typography, { align: 'center', children: "Analyze FP result time:" }), _jsxs(Typography, { align: 'center', children: ["Approx. ", secToMin(loadingFPAITime)] })] }) }));
};
export default AnalyzeAIBox;
