var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// @ts-nocheck
import * as React from 'react';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { subjectIdHelper, } from '../../utils/helper';
import { orgStyles } from '../helper/styles';
import { Datagrid, List, WrapperField, FunctionField, useDataProvider, useNotify, useRefresh, Confirm, } from 'react-admin';
import { Typography, Grid, IconButton, Button, Zoom, Box, Tooltip, } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterList from './UserHelper/UserFilterList';
import PageviewIcon from '@mui/icons-material/Pageview';
import PaginationControlled from '../../utils/Pagination';
// individual panel component
var PanelComp = function (_a) {
    var icon = _a.icon, text = _a.text, setClickedPanelComp = _a.setClickedPanelComp, backgroundColor = _a.backgroundColor;
    return (_jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", alignItems: "center", padding: 0.5, style: {
            cursor: "pointer",
            backgroundColor: backgroundColor !== null && backgroundColor !== void 0 ? backgroundColor : "white"
        }, onClick: function () { }, children: [_jsx(Typography, { variant: "body", sx: { paddingRight: "0.5rem" }, children: icon }), _jsx(Typography, { variant: "body", children: text })] }));
};
var classes = {
    noMarginDialog: {
        '&>div:nth-child(3)': {
            '&>div': {
                margin: 0,
            },
        },
    }
};
var Transition = React.forwardRef(function Transition(props, ref) {
    return _jsx(Zoom, __assign({ ref: ref }, props));
});
var UserComp = function (_a) {
    var generalData = _a.generalData, data = _a.data, params = _a.params, translate = _a.translate, filters = _a.filters, setFilters = _a.setFilters, closeDialog = _a.closeDialog, opened = _a.opened, resource = _a.resource, reloadRecord = _a.reloadRecord, setDialogData = _a.setDialogData, isLoading = _a.isLoading, searchFilterGroup = _a.searchFilterGroup, handleDelete = _a.handleDelete, paginationInfo = _a.paginationInfo, acessControlList = _a.acessControlList, total = _a.total, setTotal = _a.setTotal, count = _a.count, setCount = _a.setCount, rowsPerPage = _a.rowsPerPage, setRowsPerPage = _a.setRowsPerPage, selectedViewOrgName = _a.selectedViewOrgName;
    var location = useLocation();
    var navigate = useNavigate();
    var dataProvider = useDataProvider();
    var notify = useNotify();
    var refresh = useRefresh();
    //dialog box for confirm delete
    var _b = useState(false), confirmOpen = _b[0], setConfirmOpen = _b[1];
    var _c = useState(null), recordToDelete = _c[0], setRecordToDelete = _c[1];
    var handlePreDelete = function (record) {
        setRecordToDelete(record);
        setConfirmOpen(true);
    };
    var handleCancel = function () {
        setConfirmOpen(false);
    };
    return (_jsx(_Fragment, { children: _jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", alignItems: "start", justifyContent: "start", width: "100%", backgroundColor: "", children: [_jsx(Box, { sx: { position: 'sticky', top: 0, marginY: "1rem", paddingY: "1rem", zIndex: 1000, backgroundColor: 'white', width: "100%", paddingX: "1rem", marginBottom: 0, paddingBottom: 0, }, children: _jsx(FilterList, { translate: translate, search: searchFilterGroup, isLoading: isLoading, params: params, filters: filters, setFilters: setFilters, searchFilterGroup: searchFilterGroup }) }), _jsxs(Grid, { item: true, margin: "1rem", width: "100%", children: [_jsx(List, { resource: "".concat(resource, "/").concat(generalData.id, "/users"), empty: false, pagination: false, style: { width: "100%", backgroundColor: "", marginTop: "1rem", paddingY: "2rem" }, actions: "", children: _jsxs(Datagrid, { data: data, style: { tableLayout: "fixed" }, bulkActionButtons: false, children: [_jsx(FunctionField, { source: "id", label: translate("pages.userList.fields.id"), sortable: false, render: function (record) {
                                            return (_jsx(Typography, { style: orgStyles.textBreakWord, children: subjectIdHelper(record === null || record === void 0 ? void 0 : record.id) }));
                                        } }), _jsx(FunctionField, { source: "username", label: translate("pages.userList.fields.username"), sortable: false, render: function (record) {
                                            return (_jsx(Typography, { style: orgStyles.textBreakWord, children: record === null || record === void 0 ? void 0 : record.username }));
                                        } }), _jsx(FunctionField, { source: "lastName", label: translate("pages.subject.fields.lastname"), sortable: false, render: function (record) {
                                            return (_jsx(Typography, { style: orgStyles.textBreakWord, children: record === null || record === void 0 ? void 0 : record.Last_name }));
                                        } }), _jsx(FunctionField, { source: "firstName", label: translate("pages.subject.fields.firstname"), sortable: false, render: function (record) {
                                            return (_jsx(Typography, { style: orgStyles.textBreakWord, children: record === null || record === void 0 ? void 0 : record.First_name }));
                                        } }), _jsx(FunctionField, { source: "email", label: translate("pages.userList.fields.email"), sortable: false, render: function (record) {
                                            return (_jsx(Typography, { style: orgStyles.textBreakWord, children: record === null || record === void 0 ? void 0 : record.email }));
                                        } }), _jsx(FunctionField, { source: "gender", label: translate("pages.subject.fields.gender"), sortable: false, render: function (record) {
                                            return (_jsx(Typography, { style: orgStyles.textBreakWord, children: record === null || record === void 0 ? void 0 : record.gender }));
                                        } }), _jsx(FunctionField, { source: "userGroup", label: translate("pages.userList.fields.userGroup"), sortable: false, render: function (record) {
                                            var _a;
                                            return (_jsxs(Typography, { style: orgStyles.textBreakWord, children: [console.log(record.userGroups, '==log userGroup '), (_a = record === null || record === void 0 ? void 0 : record.userGroups) === null || _a === void 0 ? void 0 : _a.map(function (item) { return item.name; }).join(', ')] }));
                                        } }), _jsx(FunctionField, { source: "status", label: translate("pages.userList.fields.status"), sortable: false, render: function (record) {
                                            var bgColor;
                                            var statusText = "Active";
                                            switch (record.status) {
                                                case ("Disabled" || "disabled"):
                                                    bgColor = "red";
                                                    statusText = "Disabled";
                                                    break;
                                                case ("Not activate" || "Not Activate"):
                                                    bgColor = "#D58484";
                                                    statusText = "Not activate";
                                                    break;
                                                default:
                                                    bgColor = "green";
                                                    break;
                                            }
                                            return (_jsx(Button, { disabled: true, className: "small-text-button", sx: {
                                                    backgroundColor: bgColor,
                                                }, children: statusText }));
                                        } }), _jsx(WrapperField, { label: translate("pages.subject.fields.actions"), children: _jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", children: [_jsx(FunctionField, { source: "", label: "", render: function (record) {
                                                        return (_jsx(Grid, { item: true, onClick: function () {
                                                                console.log('==log ---', record);
                                                                var state = { userId: record === null || record === void 0 ? void 0 : record.id };
                                                                navigate("/admin/users", { state: state });
                                                            }, children: _jsx(Tooltip, { title: translate("tooltip.checkOutUser"), children: _jsx(IconButton, { size: "small", "aria-label": "close", style: { color: "#487ca8" }, children: _jsx(PageviewIcon, { fontSize: "small" }) }) }) }));
                                                    } }), (acessControlList.user_admin.delete ||
                                                    (acessControlList.user_normal.delete && selectedViewOrgName === localStorage.getItem('appBarOrg'))) && (_jsx(FunctionField, { source: "", label: "", render: function (record) { return (_jsx(Grid, { item: true, onClick: function () { return handlePreDelete(record === null || record === void 0 ? void 0 : record.id); }, children: _jsx(Tooltip, { title: translate("tooltip.disableUser"), children: _jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", children: _jsx(DeleteIcon, { fontSize: "small" }) }) }) })); } }))] }) })] }) }), _jsx(Grid, { item: true, xs: 12, display: "flex", flexWrap: "wrap", justifyContent: "end", children: _jsx(PaginationControlled, { searchFilterGroup: searchFilterGroup, total: total, setTotal: setTotal, count: count, setCount: setCount, rowsPerPage: rowsPerPage, setRowsPerPage: setRowsPerPage }) })] }), _jsx(Confirm, { isOpen: confirmOpen, title: translate("tooltip.disableUser"), content: translate("comfirmDialog.disableUser"), onConfirm: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, handleDelete(recordToDelete)];
                            case 1:
                                _a.sent();
                                setConfirmOpen(false);
                                return [2 /*return*/];
                        }
                    }); }); }, onClose: handleCancel })] }) }));
};
export default UserComp;
