var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Fragment, useState } from "react";
import { dbDateHelper } from "../../utils/helper";
import { DatagridConfigurable, ExportButton, List, SelectColumnsButton, TextField, TopToolbar, useListContext, WrapperField, FunctionField, useDataProvider, useNotify, useRefresh, useRecordContext, Confirm, } from "react-admin";
import { useMediaQuery, Typography, Grid, IconButton, Button, } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import DoneIcon from "@mui/icons-material/Done";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import FilterList from "./FilterList";
import { EyeMuiChoices, AIModelMuiChoices, RiskOfAdMuiChoices, StatusMuiChoices, } from "../Params";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
var ListActions = function () { return (_jsxs(TopToolbar, { children: [_jsx(SelectColumnsButton, {}), _jsx(ExportButton, {})] })); };
var AIResultsList = function (_a) {
    var isLoading = _a.isLoading, setIsLoading = _a.setIsLoading, translate = _a.translate, selectedRow = _a.selectedRow, setSelectedRow = _a.setSelectedRow, filters = _a.filters, setFilters = _a.setFilters, openExamEditDialog = _a.openExamEditDialog, delExamEditAction = _a.delExamEditAction, reloadCreateExamDateRecord = _a.reloadCreateExamDateRecord, setCurRowImgs = _a.setCurRowImgs, setData = _a.setData, setDummyCompList = _a.setDummyCompList;
    // const [isLoading, setIsloading] = useState(false);
    var _b = useState({
        eye: EyeMuiChoices,
        ai_model: AIModelMuiChoices,
        risk_of_ad: RiskOfAdMuiChoices,
        status: StatusMuiChoices,
    }), params = _b[0], setParams = _b[1];
    var dataProvider = useDataProvider();
    var notify = useNotify();
    var refresh = useRefresh();
    var resource = "exams";
    // filter group when "search"
    var searchFilterGroup = function () {
        var _a;
        setIsLoading(true);
        var sessonStorageJSON = (_a = JSON.parse(sessionStorage.getItem("".concat(window.location.pathname, "_filter")))) !== null && _a !== void 0 ? _a : {};
        //fetch all data first for left panel
        var params = {
            pagination: { page: 1, perPage: 25 },
            sort: { field: "exam_date", order: "DESC" },
            filter: __assign(__assign({ subject_id: selectedRow.id }, filters), sessonStorageJSON),
        };
        dataProvider
            .getList(resource, params)
            .then(function (data) {
            refresh();
            notify(translate("pages.exam.notify.get"));
            setIsLoading(false);
        })
            .catch(function (error) {
            // Handle any errors that occurred
            console.error(error);
            notify(translate("pages.exam.notify.getFailed"), {
                type: 'error',
            });
            setIsLoading(false);
        });
    };
    return (_jsxs(Grid, { item: true, marginTop: 2, children: [_jsx(FilterList, { translate: translate, search: searchFilterGroup, isLoading: isLoading, filters: filters, setFilters: setFilters, params: params }), _jsx(List
            // filterDefaultValues={filters}
            , { 
                // filterDefaultValues={filters}
                sort: { field: "exam_date", order: "DESC" }, perPage: 25, empty: false, 
                // filters={examFilters}
                actions: _jsx(ListActions, {}), children: _jsx(TableDatagrid, { isLoading: isLoading, translate: translate, setSelectedRow: setSelectedRow, openExamEditDialog: openExamEditDialog, delExamEditAction: delExamEditAction, reloadCreateExamDateRecord: reloadCreateExamDateRecord, setDummyCompList: setDummyCompList }) })] }));
};
/// --- PDF display related
var OpenPdfButton = function (_a) {
    var pdfSource = _a.pdfSource;
    var record = useRecordContext();
    console.log("row: ".concat(record), record, pdfSource);
    var didClick = function () {
        if (record) {
            window.open(pdfSource);
        }
        else {
            console.log("NO dataset attached!");
        }
    };
    var canOpen = function () {
        var _a;
        return ((_a = record === null || record === void 0 ? void 0 : record.fp_imgs) === null || _a === void 0 ? void 0 : _a.length) > 0;
    };
    return (_jsx(_Fragment, { children: canOpen() && (_jsx(Button, { onClick: didClick, children: _jsx(RemoveRedEyeIcon, {}) })) }));
};
/// --- End of PDF display related
var TableDatagrid = function (_a) {
    var isLoading = _a.isLoading, translate = _a.translate, setSelectedRow = _a.setSelectedRow, openExamEditDialog = _a.openExamEditDialog, delExamEditAction = _a.delExamEditAction, reloadCreateExamDateRecord = _a.reloadCreateExamDateRecord, setDummyCompList = _a.setDummyCompList;
    var listContext = useListContext();
    var isXSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down("sm");
    });
    var handleRowClick = function (id, basePath, record) {
        reloadCreateExamDateRecord(record);
        setDummyCompList([
            { name: "AI Results", bgColor: "#efefef" },
            { name: "Data Import", bgColor: "white" },
        ]);
    };
    var _b = useState(false), confirmOpen = _b[0], setConfirmOpen = _b[1];
    var _c = useState(null), recordToDelete = _c[0], setRecordToDelete = _c[1];
    var handleDelete = function (record) {
        setRecordToDelete(record);
        setConfirmOpen(true);
    };
    var handleConfirm = function () {
        if (recordToDelete) {
            delExamEditAction(recordToDelete);
        }
        setConfirmOpen(false);
    };
    var handleCancel = function () {
        setConfirmOpen(false);
    };
    return (_jsxs(Fragment, { children: [isXSmall ? (_jsx(_Fragment, {})) : (_jsx(_Fragment, { children: _jsxs(DatagridConfigurable, { style: { tableLayout: "fixed" }, bulkActionButtons: false, isLoading: isLoading, children: [_jsx(FunctionField, { source: "exam_date", label: translate("pages.exam.fields.exam_date"), render: function (record) {
                                return (_jsx(Typography, { className: "small-text", children: dbDateHelper(record.exam_date) }));
                            } }), _jsx(TextField, { className: "small-text", source: "ai_model", label: translate("pages.exam.fields.ai_model") }), _jsx(TextField, { className: "small-text", source: "eye", label: translate("pages.exam.fields.eye") }), _jsx(FunctionField, { label: translate("pages.exam.fields.risk_of_ad"), source: "risk_of_ad", render: function (record) {
                                var bgColor;
                                var displayText = record.risk_of_ad;
                                console.log(record, record.risk_of_ad, "==risk_of_ad");
                                switch (true) {
                                    case record.risk_of_ad == "High" ||
                                        record.risk_of_ad == "HIgh":
                                        bgColor = "#ff666b";
                                        break;
                                    case record.risk_of_ad == "Low":
                                        bgColor = "#156319";
                                        break;
                                    case record.risk_of_ad == null:
                                        bgColor = "red";
                                        displayText = "Pending";
                                        break;
                                }
                                return (_jsxs(_Fragment, { children: [record.risk_of_ad && (_jsx(Button, { disabled: true, className: "small-text-button", sx: {
                                                backgroundColor: bgColor,
                                            }, children: displayText })), !record.risk_of_ad && (_jsx(Typography, { variant: "body", children: "N/A" }))] }));
                            } }), _jsx(TextField, { className: "small-text", source: "confidence_level", label: translate("pages.exam.fields.confidence_level") }), _jsx(FunctionField, { source: "status", label: translate("pages.exam.fields.status"), render: function (record) {
                                var bgColor;
                                var btnIcon = _jsx(RotateRightIcon, {});
                                var displayText = record.status;
                                switch (record.status) {
                                    case "Processing":
                                        bgColor = "#8c8c8c";
                                        btnIcon = _jsx(RotateRightIcon, {});
                                        break;
                                    case "Completed":
                                        bgColor = "#359c59";
                                        btnIcon = _jsx(DoneIcon, {});
                                        break;
                                    default:
                                        bgColor = "red";
                                        displayText = "Pending";
                                        break;
                                }
                                return (_jsxs(_Fragment, { children: [record.status && (_jsxs(Button, { style: {
                                                backgroundColor: bgColor,
                                                color: "white",
                                                padding: "0.2rem",
                                                ":hover": {
                                                    background: "none",
                                                },
                                                textTransform: "none",
                                            }, children: [btnIcon, displayText] })), !record.status && _jsx(Typography, { children: "N/A" })] }));
                            } }), _jsx(FunctionField, { source: "created_at", label: translate("pages.exam.fields.analysisDate"), render: function (record) {
                                return (_jsx(Typography, { className: "small-text", children: dbDateHelper(record.created_at) }));
                            } }), _jsx(WrapperField, { label: translate("pages.subject.fields.actions"), children: _jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", children: [_jsx(FunctionField, { source: "", label: "", render: function (record) {
                                            return (_jsx(Grid, { item: true, children: record.analysisDataPdf && (_jsx(OpenPdfButton, { pdfSource: record.analysisDataPdf })) }));
                                        } }), _jsx(FunctionField, { source: "", label: "", render: function (record) {
                                            return (_jsx(Grid, { item: true, onClick: function (e) {
                                                    e.stopPropagation();
                                                    openExamEditDialog(record);
                                                }, children: _jsx(Tooltip, { title: translate("tooltip.editExam"), children: _jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", children: _jsx(EditIcon, { fontSize: "small" }) }) }) }));
                                        } }), _jsx(FunctionField, { source: "", label: "", render: function (record) {
                                            return (_jsx(Grid, { item: true, onClick: function (e) {
                                                    e.stopPropagation();
                                                    reloadCreateExamDateRecord(record);
                                                    setDummyCompList([
                                                        { name: "AI Results", bgColor: "#efefef" },
                                                        { name: "Data Import", bgColor: "white" },
                                                    ]);
                                                }, children: _jsx(Tooltip, { title: translate("tooltip.importData"), children: _jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", children: _jsx(FilePresentIcon, { fontSize: "small" }) }) }) }));
                                        } }), _jsx(FunctionField, { source: "", label: "", render: function (record) {
                                            return (_jsx(Grid, { item: true, onClick: function (e) {
                                                    e.stopPropagation();
                                                    handleDelete(record);
                                                }, children: _jsx(Tooltip, { title: translate("tooltip.deleteExam"), children: _jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", children: _jsx(DeleteIcon, { fontSize: "small" }) }) }) }));
                                        } })] }) })] }) })), _jsx(Confirm, { isOpen: confirmOpen, title: translate("tooltip.deleteExam"), content: translate("comfirmDialog.exam"), onConfirm: handleConfirm, onClose: handleCancel })] }));
};
export default AIResultsList;
