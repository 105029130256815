import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, Typography, Checkbox, } from "@mui/material";
import { styled } from "@mui/material/styles";
var StyledCheckbox = styled(Checkbox)(function (_a) {
    var theme = _a.theme;
    return ({
        opacity: 0.3,
        padding: "0.2em",
        "&:hover": {
            opacity: 1,
            transform: "scale(1.2)",
            transition: "all 0.4s ease-in-out",
            boxShadow: "0 0 12px rgba(0, 128, 255, 0.7)",
        },
        "&.Mui-checked": {
            opacity: 1,
            color: theme.palette.primary.main,
        },
    });
});
var TextCheckBox = function (_a) {
    var parentName = _a.parentName, name = _a.name, label = _a.label, labelRed = _a.labelRed, checked = _a.checked, handleCheckboxChange = _a.handleCheckboxChange, disabled = _a.disabled;
    //checkBoxType: alone, parent, child
    return (_jsxs(Grid, { className: "small-text-checkbox", display: "flex", alignItems: "center", children: [_jsx(StyledCheckbox, { disabled: disabled !== null && disabled !== void 0 ? disabled : false, name: name, onChange: function (e) {
                    handleCheckboxChange(e.target.checked, parentName, name);
                }, checked: checked }), _jsx(Typography, { className: "small-text-checkbox", children: label }), labelRed && (_jsx(Typography, { className: "small-text-checkbox", style: { color: "red", paddingLeft: "10px" }, children: "(".concat(labelRed, ")") }))] }));
};
var CompTitle = function (_a) {
    var label = _a.label;
    return (_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { className: "normal-text normal-bold", style: {
                color: "grey",
                width: "10rem",
                borderBottom: "solid 1px",
                backgroundColor: "",
            }, children: label }) }));
};
export { TextCheckBox, CompTitle };
