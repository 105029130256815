var ACLData = {
    /*  dashboard: {
         "view-general": true,
         "view-sales": true,
     },
     user: {
         "view-all": true,
         "view-sales": true,
         "view-org": true,
         "view-new": true,
         "edit-all": true,
         "edit-org": true,
         "disable-all": true,
     },
     organization: {
         "view-allOrg": true,
         "view-ownOrg": true,
         "create-org": true,
         "disable-allOrg": true,
         "disable-ownOrg": true,
         "edit-all": true,
         "edit-ownOrg": true,
         "edit-allGroup": true,
         "edit-ownGroup": true,
         "add-new": true,
         "disable-allGroup": true,
         "disable-ownGroup": true,
         "disable-orgUser": true,
     },
     subject: {
         "view-all": true,
         "view-org": true,
         "create": true,
         "edit": true,
         "delete": true,
     },
     dataExport: {
         "export-all": true,
         "export-org": true,
         "export-retinADProbScores": true,
     },
     access: {
         Dashboard: {
             add: false, view: true, edit: false, delete: false
         },
         User: {
             add: true, view: true, edit: true, delete: true
         },
         UserGroup: {
             add: false, view: false, edit: false, delete: false
         },
         Subject: {
             add: true, view: true, edit: true, delete: true
         },
         DataExport: {
             add: false, view: true, edit: false, delete: false
         }
     } */
    dashboard: {
        view_general: true,
        view_sales: true,
    },
    user: {
        view_all: true,
        view_org: true,
        create_new: true,
        edit_all: true,
        edit_org: true,
        disable_all: true,
    },
    organization: {
        view_allOrg: true,
        view_ownOrg: true,
        create_org: true,
        disable_allOrg: true,
        disable_ownOrg: true,
        edit_all: true,
        edit_ownOrg: true,
        edit_allGroup: true,
        edit_ownGroup: true,
        add_newGroup: true,
        disable_allGroup: true,
        disable_ownGroup: true,
        disable_orgUser: true,
        create_orgUser: true,
    },
    subject: {
        view_all: true,
        view_org: true,
        create: true,
        edit: true,
        delete: true,
    },
    dataExport: {
        export_all: true,
        export_org: true,
        export_retinADProbScores: true,
    },
};
// 1009 - new acl format
var acl = {
    "organization_normal": {
        "view": true, //BE
        "update": true, //ok
        "delete": true //ok
    },
    "organization_admin": {
        "create": true, //ok
        "view": true, //BE
        "update": true, //ok
        "delete": true //ok
    },
    "user_normal": {
        "create": true,
        "view": true, //BE
        "update": true, //ok
        "delete": true //ok
    },
    "user_admin": {
        "create": false,
        "view": true, //BE
        "update": true, //ok
        "delete": true //ok
    },
    "subject_normal": {
        "create": true,
        "view": true, //BE
        "update": true,
        "delete": true
    },
    "subject_admin": {
        "create": false,
        "view": true,
        "update": false,
        "delete": false
    },
    "user_group_normal": {
        "create": true, //ok
        "view": true, //BE
        "update": true, //ok (bug...)
        "delete": true //ok (bug...)
    },
    "user_group_admin": {
        "create": true, //ok
        "view": true, //BE
        "update": true, //ok
        "delete": true //ok
    },
    "data_export_normal": {
        "export": true,
        "export_retinad_probability_scores": true
    },
    "data_export_admin": {
        "export": false
    },
    "dashboard_normal": {
        "view": true //ok
    },
    "dashboard_admin": {
        "view": true
    },
};
export { ACLData, acl };
