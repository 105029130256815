import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { subjectIdHelper, longNameHelper, } from "../utils/helper";
import { DatagridConfigurable, DateField, ExportButton, List, SelectColumnsButton, TextField, TopToolbar, WrapperField, FunctionField, Confirm, } from "react-admin";
import { useMediaQuery, Typography, Grid, IconButton, Button, Container, } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
var ListActions = function () { return (_jsxs(TopToolbar, { children: [_jsx(SelectColumnsButton, {}), _jsx(ExportButton, {})] })); };
var TableDatagrid = function (_a) {
    var setOrigDialogData = _a.setOrigDialogData, acessControlList = _a.acessControlList, translate = _a.translate, setSelectedRow = _a.setSelectedRow, data = _a.data, myOrg = _a.myOrg, setData = _a.setData, setSelectedColumn = _a.setSelectedColumn, selectedColumn = _a.selectedColumn, setDialogData = _a.setDialogData, dialogData = _a.dialogData, dialogOpened = _a.dialogOpened, setDialogDataAction = _a.setDialogDataAction, closeDialog = _a.closeDialog, educationLevelArray = _a.educationLevelArray, openEditDialog = _a.openEditDialog, dataProvider = _a.dataProvider, refresh = _a.refresh, notify = _a.notify, resource = _a.resource, navigate = _a.navigate, sort = _a.sort, setOpenLeftPanel = _a.setOpenLeftPanel, filters = _a.filters, setFilters = _a.setFilters, getCurrentViewingUser = _a.getCurrentViewingUser, delAction = _a.delAction, _b = _a.tableWidth, tableWidth = _b === void 0 ? "500px" : _b;
    // const { filterValues, setFilters, displayedFilters, sort } = listContext;
    console.log(sort, "==listContext");
    var isXSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down("sm");
    });
    // const dispatch = useDispatch()
    var _c = useState(false), confirmOpen = _c[0], setConfirmOpen = _c[1];
    var _d = useState(null), recordToDelete = _d[0], setRecordToDelete = _d[1];
    var handleDelete = function (record) {
        setRecordToDelete(record);
        setConfirmOpen(true);
    };
    var handleConfirm = function () {
        if (recordToDelete) {
            delAction(recordToDelete);
        }
        setConfirmOpen(false);
    };
    var handleCancel = function () {
        setConfirmOpen(false);
    };
    var handleListChange = function () {
        console.log('==list cahnge', sort);
    };
    return (_jsxs(Container, { maxWidth: false, disableGutters: true, children: [_jsx(Grid, { container: true, children: _jsx(Grid, { item: true, xs: 12, children: _jsx(List, { filterDefaultValues: filters, sort: { field: 'id', order: 'DESC' }, empty: false, debounce: handleListChange, actions: _jsx(ListActions, {}), children: _jsxs(DatagridConfigurable, { style: { tableLayout: "fixed" }, bulkActionButtons: false, rowClick: function (id, resource, record) {
                                console.log('==logggg', record);
                                setSelectedRow(record);
                                getCurrentViewingUser(record.id);
                            }, children: [_jsx(FunctionField, { source: "id", label: translate("pages.userList.fields.id"), render: function (record) {
                                        return (_jsx(Typography, { className: "small-text", children: subjectIdHelper(record.id) }));
                                    } }), _jsx(FunctionField, { source: "organizations", label: translate("pages.userList.fields.organization"), render: function (record) {
                                        var _a;
                                        return (_jsx(Typography, { className: "small-text", children: longNameHelper((_a = record === null || record === void 0 ? void 0 : record.current_organization) === null || _a === void 0 ? void 0 : _a.orgName) }));
                                    } }), _jsx(FunctionField, { source: "userGroup", label: translate("pages.userList.fields.userGroup"), render: function (record) {
                                        var _a;
                                        return (_jsxs(Typography, { className: "small-text", children: [console.log(record.userGroups, '==log userGroup '), (_a = record.userGroups) === null || _a === void 0 ? void 0 : _a.map(function (item) { return item.name; }).join(', ')] }));
                                    } }), _jsx(TextField, { className: "small-text", source: "username", label: translate("pages.userList.fields.username") }), _jsx(TextField, { className: "small-text", source: "Last_name", label: translate("pages.subject.fields.lastname") }), _jsx(TextField, { className: "small-text", source: "First_name", label: translate("pages.subject.fields.firstname") }), _jsx(TextField, { className: "small-text", source: "email", label: translate("pages.userList.fields.email") }), _jsx(TextField, { className: "small-text", source: "gender", label: translate("pages.subject.fields.gender") }), _jsx(FunctionField, { source: "status", label: translate("pages.userList.fields.status"), render: function (record) {
                                        var bgColor;
                                        var hoverColor;
                                        var statusText = "Active";
                                        switch (record.status) {
                                            case ("Disabled" || "disabled"):
                                                bgColor = "red";
                                                hoverColor = "#FF6347"; // Tomato red
                                                statusText = "Disabled";
                                                break;
                                            case ("Not activate" || "Not Activate"):
                                                bgColor = "#D58484";
                                                hoverColor = "#FF6666"; // Tomato red
                                                statusText = "Not activate";
                                                break;
                                            default:
                                                bgColor = "green";
                                                hoverColor = "#4CAF50"; // Lighter green
                                                break;
                                        }
                                        return (_jsx(_Fragment, { children: _jsx(Button, { disabled: true, className: "small-text-button", sx: {
                                                    backgroundColor: bgColor,
                                                }, children: statusText }) }));
                                    } }), _jsx(DateField, { source: "createdAt", label: translate("pages.userList.fields.createdAt") }), _jsx(WrapperField, { label: translate("pages.subject.fields.actions"), children: _jsxs(Grid, { container: true, spacing: 0, alignItems: "center", children: [_jsx(FunctionField, { render: function (record) {
                                                    if (true) {
                                                        return (_jsx(Grid, { item: true, children: _jsx(Tooltip, { title: translate("tooltip.viewUser"), children: _jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", onClick: function () {
                                                                        console.log('==logggg', record);
                                                                        setSelectedRow(record);
                                                                        getCurrentViewingUser(record.id);
                                                                    }, children: _jsx(PreviewIcon, { fontSize: "small" }) }) }) }));
                                                    }
                                                    return null;
                                                } }), _jsx(FunctionField, { render: function (record) {
                                                    var _a;
                                                    var isMyOrg = ((_a = record.current_organization) === null || _a === void 0 ? void 0 : _a.orgName) === myOrg;
                                                    var canEdit = (acessControlList.user_admin.update ||
                                                        (acessControlList.user_normal.update && isMyOrg));
                                                    if (canEdit) {
                                                        return (_jsx(Grid, { item: true, children: _jsx(Tooltip, { title: translate("tooltip.editUser"), children: _jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", onClick: function () {
                                                                        openEditDialog(record);
                                                                    }, children: _jsx(EditIcon, { fontSize: "small" }) }) }) }));
                                                    }
                                                    return null;
                                                } }), _jsx(FunctionField, { render: function (record) {
                                                    var _a;
                                                    var isMyOrg = ((_a = record.current_organization) === null || _a === void 0 ? void 0 : _a.orgName) === myOrg;
                                                    var canDelete = (acessControlList.user_admin.delete ||
                                                        (acessControlList.user_normal.delete && isMyOrg));
                                                    if (canDelete) {
                                                        return (_jsx(Grid, { item: true, children: _jsx(Tooltip, { title: translate("tooltip.deleteUser"), children: _jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", onClick: function () { handleDelete(record); }, children: _jsx(DeleteIcon, { fontSize: "small" }) }) }) }));
                                                    }
                                                    return null;
                                                } })] }) })] }) }) }) }), _jsx(Confirm, { isOpen: confirmOpen, title: translate("tooltip.deleteUser"), content: translate("comfirmDialog.user"), onConfirm: handleConfirm, onClose: handleCancel })] }));
};
export default TableDatagrid;
