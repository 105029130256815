import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, } from "@mui/material";
import { ExpandMore, FileDownload, FolderSpecial, ManageAccounts, People, Person, Settings, SpaceDashboard, Subject, } from "@mui/icons-material";
import { useTranslate, useSidebarState, Link, } from "react-admin";
// Sidebar items
var sidebarList = [
    {
        id: 1,
        path: "/admin/dashboard",
        icon: _jsx(SpaceDashboard, {}),
        text: "pages.dashboard.name",
    },
    {
        id: 2,
        path: "/admin/subjects",
        icon: _jsx(Subject, {}),
        text: "pages.subject.name",
    },
    {
        id: 3,
        path: "/admin/dataexport",
        icon: _jsx(FileDownload, {}),
        text: "pages.dataExport.name",
    },
    {
        id: 4,
        path: "/admin/users",
        icon: _jsx(Person, {}),
        text: "pages.userList.name",
    },
    {
        id: 5,
        path: "/admin/organizations",
        icon: _jsx(People, {}),
        text: "pages.organizations.name",
    },
    {
        id: 6,
        path: "/admin/settings",
        icon: _jsx(Settings, {}),
        text: "pages.settings.name",
    },
];
var accordionStyle = {
    "&::before": {
        display: "none",
    },
    boxShadow: "none",
    "&.MuiPaper-root": {
        height: "-webkit-fill-available",
    },
};
var Menu = function (_a) {
    var _b, _c, _d, _e;
    var _f = _a.dense, dense = _f === void 0 ? false : _f;
    var _g = React.useState(1), selectedIndex = _g[0], setSelectedIndex = _g[1];
    var location = useLocation();
    var translate = useTranslate();
    var open = useSidebarState()[0];
    React.useEffect(function () {
        switch (location.pathname) {
            case "/admin/dashboard":
                setSelectedIndex(1);
                break;
            case "/admin/subjects":
            case "/admin/exams":
                setSelectedIndex(2);
                break;
            case "/admin/dataexport":
                setSelectedIndex(3);
                break;
            case "/admin/users":
                setSelectedIndex(4);
                break;
            case "/admin/organizations":
                setSelectedIndex(5);
                break;
            case "/admin/settings":
                setSelectedIndex(6);
                break;
            default:
                setSelectedIndex(1);
                break;
        }
    }, [location]);
    /**
     * Generate sidebar item
     * @param id Item id
     * @param path Path that redirects to
     * @param icon Item icon
     * @param text Item text
     * @returns The ReactNode of the sidebar item
     */
    var generateList = function (id, path, icon, text, noPadding) { return (_jsx(Link, { to: path, color: "black", children: _jsx(ListItem, { sx: noPadding && { paddingRight: 0 }, children: _jsxs(ListItemButton, { selected: selectedIndex === id, onClick: function () {
                    setSelectedIndex(id);
                }, sx: { minWidth: 35 }, children: [_jsx(ListItemIcon, { sx: { minWidth: 35 }, children: icon }), _jsx(ListItemText, { primary: _jsx(Typography, { variant: "body2", sx: { fontSize: "0.8em", fontWeight: "bold" }, children: translate(text, {
                                smart_count: 2,
                            }) }) })] }) }, "navBar".concat(id)) })); };
    return (open && (_jsx(Drawer, { variant: "permanent", sx: {
            paddingTop: "20px",
        }, children: _jsxs(List, { children: [(_b = sidebarList === null || sidebarList === void 0 ? void 0 : sidebarList.slice(0, 1)) === null || _b === void 0 ? void 0 : _b.map(function (_) { return generateList(_.id, _.path, _.icon, _.text, false); }), _jsxs(Accordion, { defaultExpanded: true, sx: accordionStyle, disableGutters: true, children: [_jsx(AccordionSummary, { expandIcon: _jsx(ExpandMore, {}), children: _jsxs(ListItem, { children: [_jsx(ListItemIcon, { sx: { minWidth: 35 }, children: _jsx(FolderSpecial, {}) }), _jsx(ListItemText, { primary: _jsx(Typography, { variant: "body2", sx: { fontSize: "0.8em", fontWeight: "bold" }, children: translate("pos.menu.main", {
                                                smart_count: 2,
                                            }) }) })] }) }), _jsx(AccordionDetails, { children: (_c = sidebarList === null || sidebarList === void 0 ? void 0 : sidebarList.slice(1, 3)) === null || _c === void 0 ? void 0 : _c.map(function (_) { return generateList(_.id, _.path, _.icon, _.text, true); }) })] }), _jsxs(Accordion, { defaultExpanded: true, sx: accordionStyle, disableGutters: true, children: [_jsx(AccordionSummary, { expandIcon: _jsx(ExpandMore, {}), children: _jsxs(ListItem, { children: [_jsx(ListItemIcon, { sx: { minWidth: 35 }, children: _jsx(ManageAccounts, {}) }), _jsx(ListItemText, { primary: _jsx(Typography, { variant: "body2", sx: { fontSize: "0.8em", fontWeight: "bold" }, children: translate("pos.menu.userManagement", {
                                                smart_count: 2,
                                            }) }) })] }) }), _jsx(AccordionDetails, { children: (_d = sidebarList === null || sidebarList === void 0 ? void 0 : sidebarList.slice(3, 5)) === null || _d === void 0 ? void 0 : _d.map(function (_) { return generateList(_.id, _.path, _.icon, _.text, true); }) })] }), (_e = sidebarList === null || sidebarList === void 0 ? void 0 : sidebarList.slice(5, 6)) === null || _e === void 0 ? void 0 : _e.map(function (_) { return generateList(_.id, _.path, _.icon, _.text, false); })] }) })));
};
export default Menu;
