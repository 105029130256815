import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { AppBar, useRefresh, } from 'react-admin';
import { Box, useMediaQuery, Tooltip, } from '@mui/material';
import { Link } from 'react-router-dom';
// import Logo from '../assets/logos/header logo.png'
import ICSLogo from '../assets/logos/iCogSci Logo_Design 1ab (no wording) (glow) - cropped.png';
import { AppBarToolbar } from './AppBarToolbar';
var CustomAppBar = function () {
    var _a;
    var isLargeEnough = useMediaQuery(function (theme) {
        return theme.breakpoints.up('sm');
    });
    var refresh = useRefresh();
    var _b = useState(""), org = _b[0], setOrg = _b[1];
    useEffect(function () {
        var appBarOrg = localStorage.getItem("appBarOrg");
        if (appBarOrg) {
            setOrg(appBarOrg);
            console.log("==log auth app bar", appBarOrg);
        }
    }, []);
    useEffect(function () {
        var appBarOrg = localStorage.getItem("appBarOrg");
        if (appBarOrg) {
            setOrg(appBarOrg);
            console.log("==log auth app bar 2", appBarOrg);
            refresh();
            // // Event handler for localStorage changes
            // const handleStorageChange = (event) => {
            //     if (event.key === 'appBarOrg') {
            //         console.log("new name ==", event.newValue)
            //         setOrgName(event.newValue || "N/A"); // Update state with new value
            //     }
            // };
            // // Add storage event listener
            // window.addEventListener('storage', handleStorageChange);
        }
    }, [localStorage.getItem('appBarOrg')]);
    useEffect(function () {
        console.log(" ");
        refresh();
    }, [org]);
    // useEffect(() => {
    //     // Update localStorage whenever appBarOrg changes
    //     localStorage.setItem("appBarOrg", JSON.stringify(appBarOrg));
    // }, [appBarOrg]); // Dependency array includes appBarOrg
    return (_jsx(Box, { flexGrow: 1, children: _jsxs(AppBar, { toolbar: _jsx(AppBarToolbar
            // orgName={org ?? "N/A"}
            , { 
                // orgName={org ?? "N/A"}
                orgName: (_a = localStorage.getItem("appBarOrg")) !== null && _a !== void 0 ? _a : "N/A" }), sx: {
                backgroundColor: "#1561a5",
            }, children: [isLargeEnough && (_jsx(Tooltip, { title: "go to Dashboard", arrow: true, children: _jsx(Link, { to: "/admin/dashboard", style: { height: 68 }, children: _jsx("img", { src: ICSLogo, alt: "logo", height: 68 }) }) })), isLargeEnough &&
                    _jsx(_Fragment, { children: _jsx(Box, { component: "span", sx: { flex: 1 } }) })] }) }));
};
export default CustomAppBar;
