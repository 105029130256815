var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { Admin, CustomRoutes, Resource, localStorageStore, StoreContextProvider, } from 'react-admin';
// import { Route } from 'react-router';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
// import authProvider from './demoServerAndData/authProvider';
// import dataProviderFactory from './demoServerAndData/dataProvider';
import { authProvider } from './providers/authProvider';
import { dataProvider } from './providers/dataProvider';
import englishMessages from './i18n/en';
import { Layout, Login } from './layout';
import DataExport from './dataExportPage/DataExport';
import Settings from './settingsPage/Settings';
import RetinADReport1 from './examsPage/fp/RetinADReport1';
import "./App.css";
import { Provider } from 'react-redux';
// import { configureStore } from '@reduxjs/toolkit';
import configureStore from './utils/store/store';
// import reducer from './utils/store/store';
import subject from './subjectPage';
import exam from './examsPage';
import userList from './userPage';
import organization from './OrganizationPage';
import dashboard from './dashboardPage';
import { Typography } from '@mui/material';
var store = localStorageStore(undefined, 'AISP');
var AdminPortal = function () {
    var _a;
    var i18nProvider = polyglotI18nProvider(function (locale) {
        if (locale === 'zh-HK') {
            return import('./i18n/zh-HK').then(function (messages) { return messages.default; });
        }
        // Always fallback on english
        return englishMessages;
    }, 'en', [
        { locale: 'en', name: 'English' },
        { locale: 'zh-HK', name: '繁中' },
    ]);
    var cachedAuth = (_a = JSON.parse(localStorage.getItem("auth"))) !== null && _a !== void 0 ? _a : {};
    if (cachedAuth) {
        console.log(cachedAuth, '==cachedAuth in <admin');
    }
    return (_jsxs(Admin, { title: "AISP", basename: '/admin', store: store, requireAuth: true, authProvider: authProvider, dataProvider: dataProvider, loginPage: Login, layout: Layout, i18nProvider: i18nProvider, disableTelemetry: true, defaultTheme: "light", children: [_jsx(CustomRoutes, { children: _jsx(Route, { path: "/unauthorized", element: _jsx(Typography, { variant: "h4", padding: 4, children: "404 not found" }) }) }), _jsxs(CustomRoutes, { children: [_jsx(Route, { path: "/settings", element: _jsx(Settings, {}) }), _jsx(Route, { path: '/fp/report', element: _jsx(RetinADReport1, {}) }), _jsx(Route, { path: "/dataexport", element: _jsx(DataExport, {}) })] }), _jsx(Resource, __assign({ name: "exams" }, exam, { options: { label: '' } })), _jsx(Resource, __assign({ name: "subjects" }, subject, { options: { label: '' } })), _jsx(Resource, __assign({ name: "users" }, userList, { options: { label: ' ' } })), _jsx(Resource, __assign({ name: "organizations" }, organization, { options: { label: ' ' } })), _jsx(Resource, __assign({ name: "dashboard" }, dashboard, { options: { label: '' } }))] }));
};
var App = function () {
    return (_jsx(Provider, { store: configureStore, children: _jsx("div", { style: { maxHeight: "95vh", }, children: _jsx(BrowserRouter, { children: _jsxs(Routes, { children: [_jsx(Route, { path: '/', element: _jsx(Navigate, { to: '/admin', replace: true }) }), _jsx(Route, { path: "/admin/*", element: _jsx(AdminPortal, {}) })] }) }) }) }));
};
var AppWrapper = function () { return (_jsx(StoreContextProvider, { value: store, children: _jsx(App, {}) })); };
export default AppWrapper;
